export function getUserName(user, includeRole) {
    let name = "N/A";
    //console.log((user,includeRole);)
    if (user) {
        if (user.display) {
            name = user.display;
        }else
        if (user.name) {
            name = user.name;
        }else if(user.user) {
            name=user.user.firstName+(user.user.lastName?(" "+user.user.lastName):"")
        }
        if (includeRole) {

            name += " (" + user.userRole.name + ")";
        }
    }
    return name;
}

export function getUserNameFromAllUsers(usersById,userId){

}