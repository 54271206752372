import { createSlice } from "@reduxjs/toolkit";
import swal from "sweetalert";
import * as api from "../api";
import { TOKEN_NAME, REFRESH_TOKEN_NAME, DEVICE_REGISTERED } from "../config";
import firebase from "../firebase/auth";
import { setCookie, getCookie, uuidv4 } from '../utils/cookieUtil'
const initState = {
  profile: [],
  isSignedIn: false,
  deviceRegistered: false
};


const authSlice = createSlice({
  name: "auth",
  initialState: initState,
  reducers: {
    setUser(state, { action, payload }) {
      state.profile = payload;
      state.isSignedIn = true;
      registerDeviceWithAPI(state, payload);
    },
    removeUser(state, { action, payload }) {
      state.profile = {};
      state.isSignedIn = false;
    },
  },
});
async function registerDeviceWithAPI(state, payload) {
  const messaging = firebase.messaging();
  if (!sessionStorage.getItem(DEVICE_REGISTERED)) {
    messaging
      .getToken()
      .then((token) => {


        var devId = getCookie('uuid');
        if (devId == '') {
          devId = uuidv4();
          setCookie('uuid', devId, 365);
        }
        api.registerDevice(token, devId, payload.tenantUserId);
        sessionStorage.setItem(DEVICE_REGISTERED, true);

        // registerDevice(auth.profile)l
      })
      .catch((err) => {
        //console.log((err);)
      });
  }


}
export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

export function signIn(email, password, tenant) {
  return async (dispatch, getState) => {
    try {
      const response = await api.signIn(email, password, tenant);
      if (response && response.success) {
        sessionStorage.setItem(TOKEN_NAME, response.data.accessToken);
        sessionStorage.setItem(REFRESH_TOKEN_NAME, response.data.refreshToken);
      } else {
        swal(response.message);
      }

      return response;
    } catch (err) {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error Signing in"));
      throw err;
    }
  };
}
