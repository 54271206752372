import { TOKEN_NAME, COMPANY_STATUS } from "../config";

export function isLoggedin() {
  if (sessionStorage.getItem(TOKEN_NAME)) {
    return true;
  }
  return false;
}

export function isCompanyValidated() {
  if (sessionStorage.getItem(COMPANY_STATUS)) {
    return true;
  }
  return false;
}

export function isValidEmail(email){
  if (typeof email !== "undefined") {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  
    return pattern.test(email);
  }
  return false;
}