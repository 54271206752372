import React, { useState, useEffect, setState } from "react";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import { createJobDetail, getJobDetails, getJobDetailSummary, getJobDetail, updateJobDetail, deleteJobDetail } from "../../../api";

import { PageLayout } from "../../../components/pageLayout";
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import {
  FormControl, Grid, Paper, Select, Typography,
  TextField, InputLabel, IconButton, Collapse, Box, Checkbox,
  MenuItem
} from "@material-ui/core";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';


import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import i18n from "i18next";



var frequencies = {
  "DAILY": "Daily",
  "WEEKLY": "Weekly",
  "QUARTERLY": "Monthly",
  "MONTHLY": "Quaterly",
  "ANNUALLY": "Annually",
}

export function RegisterJobDetail(props) {
  const userState = useSelector((state) => state.auth.profile);
  const companyState = useSelector((state) => state.company.profile);
  const [specs, setSpecs] = useState([]);
  const [endSpecs, setEndSpecs] = useState([]);
  const [startSpecs, setStartSpecs] = useState([]);
  const [inspections, setInspections] = useState([]);
  const [feedbacks, setFeedbacks] = useState([]);

  const [error, setError] = useState("");
  const [isEditMode, setEditMode] = useState(false);

  const [jobDetailSummaries, setJobDetailSummaries] = useState([]);

  var jobDetailRegisterRequest = {};
  const allClientData = useSelector((state) => state.users.byId);
  const allClientIds = useSelector((state) => state.users.allIds);
  const allClientDataArray = allClientIds.map((key) => allClientData[key]);

  const titleSpec = "Tasks";
  const titleAudit = "Audit";
  const titleEndSpec = "Job End Tasks";


  const taskTypes = {
    "Tasks": {
      title: i18n.t('jd.add.form.specifications.title',"Tasks"),
      buttonName: i18n.t('jd.add.form.specifications',"Specifications"),
      key: "tasks",
      setMethod: setSpecs,
      items: specs,
      active: true
    },
    "Audit": {
      title: i18n.t('jd.add.form.inspections.title',"Audit"),
      key: "audit",
      buttonName: i18n.t('jd.add.form.inspections',"Inspections"),
      setMethod: setInspections,
      items: inspections,
      active: false

    },
    "Job End Tasks": {
      title: i18n.t('jd.add.form.clock-out-specs.title',"Tasks before clock-out"),
      key: "end-tasks",
      buttonName: i18n.t('jd.add.form.clock-out-specs',"Clock out specs"),
      setMethod: setEndSpecs,
      items: endSpecs,
      active: false

    },
    "Job Start Tasks": {
      title: i18n.t('jd.add.form.clock-in-specs.title',"Tasks before clock-in"),
      key: "start-tasks",
      buttonName: i18n.t('jd.add.form.clock-in-specs',"Clock in specs"),
      setMethod: setStartSpecs,
      items: startSpecs,
      active: false
    },

    "Client Feedback Tasks": {
      title: i18n.t('jd.add.form.client-feedbacks.title',"Feedbacks"),
      key: "feedback-tasks",
      buttonName: i18n.t('jd.add.form.client-feedbacks',"Client Feedbacks"),
      setMethod: setFeedbacks,
      items: feedbacks,
      active: false
    }
  }

  const [viewMode, setViewMode] = useState(false);



  useEffect(async () => {

    setViewMode(props.viewMode ? props.viewMode : false);
    if (props.viewMode) {
      loadJobDetailFromApi(props.jobDetailId);
    } else {
      setEditMode(props.location && props.location.pathname.includes('/edit'));
    }
    if (error && error.trim().length > 0) {
      swal(error);
    }
  }, [error]);
  function getSpecs(specs, promptType) {
    var specsArr = [];
    specs.forEach((spec) => {
      specsArr.push(
        {
          "title": spec.title,
          "frequency": spec.frequency,
          "description": spec.description,
          "jobSpecPromptType": promptType,
          "jobSpecItems": getSubSpecs(spec.subItems)
        }

      );
    });
    return specsArr;
  }

  function getSubSpecs(subSpecs) {
    var subSpecsArr = [];
    subSpecs.forEach((subSpec) => {
      subSpecsArr.push(
        {
          "title": subSpec.title,
          "frequency": subSpec.frequency,
          "specUserAnswers": getSpecUserAnswers(subSpec.questions)
        }

      );
    });
    return subSpecsArr;
  }

  function getSpecUserAnswers(qs) {
    var specUserAnswersArr = [];
    qs.forEach((q) => {
      specUserAnswersArr.push(
        {
          "mandatory": q.mandatory,
          "answerType": q.type,
          "title": q.title
        }

      );
    });
    return specUserAnswersArr;
  }
  function getSpecMap() {
    var specMap = {
      "NORMAL": getSpecs(specs, "START_WORK"),
      "INSPECTION": getSpecs(inspections, "START_WORK"),
      "FEEDBACK": getSpecs(feedbacks, "START_WORK"),
    };

    specMap['NORMAL'] = specMap['NORMAL'].concat(getSpecs(endSpecs, "END_WORK"));
    specMap['NORMAL'] = specMap['NORMAL'].concat(getSpecs(startSpecs, "BEFORE_START"));

    return specMap;

  }

  async function submitJobDetail(e) {
    e.preventDefault();
    var valid = true;
    const clientId = parseInt(document.getElementById('client').value);
    const selectedJdId = document.getElementById('jobDetail') ? parseInt(document.getElementById('jobDetail').value) : -1;
    if (clientId == -1) {
      swal("Please select a client to continue");
      valid = false;
    }

    if (isEditMode && selectedJdId == -1) {
      valid = false;
      swal("Please select a job detail to continue");
    }
    if (specs.length == 0 && inspections.length == 0) {
      valid = false;
      swal("Task list is empty. Please add some tasks to continue");
    } else {
      jobDetailRegisterRequest['title'] = document.getElementById('title').value;
      jobDetailRegisterRequest['tenantId'] = companyState.id;
      jobDetailRegisterRequest['specMap'] = getSpecMap();
      jobDetailRegisterRequest['clientId'] = parseInt(document.getElementById('client').value);
    }
    //console.log((jobDetailRegisterRequest);)
    if (error.trim().length == 0 && valid) {
      var res = null;

      if (isEditMode) {
        res = await updateJobDetail(selectedJdId, jobDetailRegisterRequest);
      } else {
        res = await createJobDetail(jobDetailRegisterRequest);
      }

      swal({
        title: res && res.success ? 'Success' : 'Error',
        text: res && res.message ? res.message : "Something went wrong",
        icon: res && res.success ? 'success' : 'error',
        buttons: "Close"
      }).then(() => {
        setEndSpecs([]);
        setSpecs([]);
        setInspections([]);
        setStartSpecs([]);
        setFeedbacks([]);
        jobDetailRegisterRequest = {};
        document.getElementById('title').value = "";
        loadJobDetails(document.getElementById('client').value);

      }
      );


    }


    setError("");
    const form = e.target;


  }
  function validateJobDetail(form) {

    const title = form.title.value;
    if (!title || title.trim().length == 0) {
      setError("")
    }


  }
  function getSubSpec(subtitle) {
    return {
      title: subtitle,
      questions: [{
        type: "CHECKBOX",
        mandatory: true
      }]
    }
  }
  function getQuestion(title) {
    return {
      type: "TEXT",
      mandatory: false
    }
  }

  useEffect(async () => {
    if (!isEditMode) {
      const subspec = {
        title: "",
        frequency: "DAILY",
        questions: [{
          type: "CHECKBOX",
          mandatory: true
        }]
      }
      const mainSpec = {
        title: "",
        expanded: true,
        rowId: 1212,
        subItems: [subspec],
        frequency: "DAILY"

      }
      setSpecs([mainSpec]);
    }
    // props.item.subsItems
    //props.mainItems
  }, []);
  async function loadJobDetails(cliendId, force) {
    if (isEditMode || force) {
      if (cliendId != -1) {
        const res = await getJobDetailSummary(companyState.id, cliendId);
        setJobDetailSummaries(res.data);
      }

    }
  }

  async function onClientChange(e) {
    jobDetailRegisterRequest['clientId'] = parseInt(e.target.value);
    loadJobDetails(document.getElementById('client').value);
  }

  async function onJobDetailChange(id, copy) {
    loadJobDetailFromApi(id, copy);
  }
  async function loadJobDetailFromApi(jobDetailId, copy) {
    const res = await getJobDetail(jobDetailId);
    if (res.success) {
      process(res.data, copy);
    } else {
      swal({
        title: "Error",
        text: res.message + "",
        icon: "error"
      })
    }
  }


  function getQuestionsFromDTO(qDtos) {
    var qs = [];
    if (qDtos) {
      qDtos.forEach((q) => {
        qs.push({
          type: q.answerType,
          mandatory: q.mandatory,
          title: q.tit

        })
      })
    }
    return qs;
  }
  var selectedJdId = null;
  function process(jd, copy) {
    var specs = [];
    var inspections = [];
    var feedbacks = [];
    var endSpecs = [];
    selectedJdId = jd.id;

    const pushToArray = (spec, defaultArray) => {
      if (spec.jobSpecPromptType === "END_WORK") {
        endSpecs.push(spec);
      } else if (spec.jobSpecPromptType === "BEFORE_START") {
        startSpecs.push(spec);
      } else {
        defaultArray.push(spec);
      }

    }
    if (jd.specs) {
      jd.specs.forEach((spec) => {
        var mainSpec = spec;
        var subItems = [];
        mainSpec.title = spec.title;
        mainSpec.frequency = spec.frequency;
        mainSpec.rowId = spec.id;
        spec.rowId = spec.id;
        if (spec.specItems) {
          spec.specItems.forEach((specItem) => {
            subItems.push(
              {
                title: specItem.title,
                frequency: specItem.frequency,
                questions: getQuestionsFromDTO(specItem.questionnaires)
              }
            );
          })
        }
        mainSpec.subItems = subItems;
        switch (spec.jobSpecType) {
          case "NORMAL":
            pushToArray(mainSpec, specs);
            break;
          case "INSPECTION":
            pushToArray(mainSpec, inspections);
            break;
          case "FEEDBACK":
            pushToArray(mainSpec, feedbacks);
        }
      })
    }
    setTitle(jd.title);
    setSpecs([...specs]);
    setInspections([...inspections]);
    setEndSpecs([...endSpecs]);
    setFeedbacks([...feedbacks]);
  }
  function setTitle(title) {
    if (document.getElementById('title')) {
      document.getElementById('title').value = title;
    }

  }
  const [open, setOpen] = useState(false);

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: "80%",
      margin: "auto"
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }));
  const [editJD, SetEditJD] = useState(-1);

  const classes = useStyles();
  const SelectJobDetail = () => {



    const [copyJD, SetCopyJD] = useState(-1);
    useEffect(() => {
      SetCopyJD(-1);

    }, []);
    async function copyJobDetail(e) {
      if (copyJD != -1) {
        await onJobDetailChange(copyJD, true);
        setOpen(false);
      }
      else {
        swal({
          title: "Empty",
          text: "Select a job detail",
          icon: "error"
        });
      }
    }
    return (
      <div className="card col-sm-12 grid-margin stretch-card">
        <div className="card-body text-align-left">

          <h4 className="card-title">    {i18n.t('btn.copy-from',"Copy from")}
          </h4>
          <p className="card-description">    {i18n.t('jd.copy.form.description',"Select Job Detail to copy from")}
          </p>

          <div className="forms-sample" >


            <div className="row">




              <div className="form-group col-sm-12">
                <label htmlFor="jobDetail">    {i18n.t('jd.copy.form.jd',"Job Detail")}
                </label>
                <select
                  className="form-control form-control-sm"
                  id="jobDetail"
                  onChange={(e) => {
                    SetCopyJD(e.target.value);
                  }}
                  required
                >
                  <option value="-1">    {i18n.t('jd.copy.form.jd.select',"Select job detail...")}
                  </option>
                  {jobDetailSummaries &&
                    jobDetailSummaries.map((jobDetailSummary) => {
                      return (
                        <option value={jobDetailSummary.id} key={jobDetailSummary.id}>
                          {jobDetailSummary.id + ". " + jobDetailSummary.title}
                        </option>
                      );

                    })}
                </select>
              </div>

            </div>



            <div className="row justify-flex-end">
              <button className="btn btn-outline-secondary mr-2"
                type="button"

                onClick={
                  () => {

                    setOpen(false);

                  }
                }

              >    {i18n.t('btn.cancel',"Cancel")}
              </button>


              <button className="btn btn-primary"
                type="button"
                disabled={copyJD == -1}

                onClick={
                  () => {

                    copyJobDetail();

                  }
                }

              >    {i18n.t('btn.copy',"Copy")}
              </button>
            </div>
          </div>


        </div>

      </div>

    );

  }



  async function deleteJD(id) {
    if (id != -1) {


      swal(
        {
          title: 'Confirm',
          text: 'You cannot undo this. Are you sure to remove this ?',
          icon: 'warning',
          buttons: {
            cancel: "Close",
            submit: {
              text: "Delete",
              value: "delete"
            }
          }

        }
      ).then((val) => {
        if (val == 'delete') {
          return deleteJobDetail(id);

        }
        throw null;
      }).then((res) => {
        swal({
          title: res && res.success ? 'Success' : 'Error',
          text: res && res.message ? res.message : "Something went wrong",
          icon: res && res.success ? 'success' : 'error',
          buttons: "Close"
        });
        return res && res.success;
      })
        .then(
          (s) => {
            if (s) {
              SetEditJD(-1);
            }
            loadJobDetails(document.getElementById('client').value);
          }
        )
        ;

    }
  }




  return (
    <PageLayout>
      <div className="content-wrapper ">
        <div className="col-12 grid-margin stretch-card col-lg-12">
          <div className="card">
            <div className="card-body">
              {!viewMode && (

                <div className="row">

                  <div>
                    <h4 className="card-title">{isEditMode ? i18n.t('jd.update.form.title',"Update job specs") : i18n.t('jd.add.form.title',"Add job specs")} </h4>
                    <p className="card-description">{isEditMode ? i18n.t('jd.update.form.description',"Configure job specifications and audit items. Please note that changes will not affect to previous completed jobs. Applies to new tasks and jobs") : i18n.t('jd.add.form.description',"Configure job specifications and audit items")}</p>

                  </div>

                  {!isEditMode && (
                    <div className=" col flex justify-flex-end">
                      <button type="button"
                        className="btn btn-warning"
                        onClick={(e) => {
                          loadJobDetails(null, true);
                          setOpen(true);
                        }}
                      >    {i18n.t('jd.copy.form.title',"Copy From")}
                      </button>
                    </div>
                  )}

                </div>
              )}

              <Modal
                open={open}
                className={classes.modal}
                onClose={() => { setOpen(false) }}
                closeAfterTransition

                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <SelectJobDetail
                  >
                  </SelectJobDetail>
                </Fade>

              </Modal>


              <form className="forms-sample" onSubmit={submitJobDetail} >

                {!viewMode && (
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="client">    {i18n.t('jd.add.form.client',"Select client")}
                      </label>
                      <select
                        className="form-control form-control-sm"
                        id="client"
                        onChange={onClientChange}
                        required
                      >
                        <option value="-1">    {i18n.t('jd.add.form.client.select',"Select client...")}
                        </option>
                        {allClientDataArray &&
                          allClientDataArray.map((client) => {
                            if (client.userRole.roleLevel === 10) {
                              return (
                                <option value={client.id} key={client.id}>
                                  {client.user.firstName + " " + client.user.lastName}
                                </option>
                              );
                            }
                          })}
                      </select>
                    </div>


                    {/* add job detail select */}

                    {isEditMode && (
                      <>
                        <div className="form-group col-md-10 col-xl-11">
                          <label htmlFor="jobDetail">    {i18n.t('jd.copy.form.jd',"Job Detail")}
                          </label>
                          <select
                            className="form-control form-control-sm"
                            id="jobDetail"
                            onChange={(e) => {
                              onJobDetailChange(e.target.value);
                              SetEditJD(e.target.value);
                            }}
                            required
                          >
                            <option value="-1">    {i18n.t('jd.copy.form.jd.select',"Select job detail...")}
                            </option>
                            {jobDetailSummaries &&
                              jobDetailSummaries.map((jobDetailSummary) => {
                                return (
                                  <option value={jobDetailSummary.id} key={jobDetailSummary.id}>
                                    {jobDetailSummary.id + ". " + jobDetailSummary.title}
                                  </option>
                                );

                              })}
                          </select>
                        </div>
                        <div className="col-md-2 col-xl-1 m-a">
                          <button className="btn btn-danger w-100"

                            type="button"
                            onClick={() => {
                              deleteJD(editJD);
                            }}
                            disabled={editJD == -1}
                          >    {i18n.t('btn.delete',"Delete")}
                          </button>
                        </div>
                      </>
                    )}


                  </div>
                )}


                <div className="row">
                  <div className="form-group col-sm-12">
                    <label htmlFor="title">    {i18n.t('jd.add.form.jd.title',"Job Detail Title")}
                    </label>
                    <input
                      type="text"
                      name="title"
                      className="form-control form-control-sm"
                      id="title"
                      placeholder=    {i18n.t('jd.add.form.jd.title.placeholder',"Title")}

                      required
                    />
                  </div>

                </div>
                <hr />

                <ul className="nav nav-pills nav-pills-success">

                  {Object.values(taskTypes).map((tt) => {

                    return (

                      <li className="nav-item">
                        <a className={"nav-link " + (tt.active ? "active" : "")}
                          id={"id-pills-" + tt.key}
                          data-toggle="pill"
                          href={"#pills-" + tt.key}

                          role="tab" aria-controls={"pills-" + tt.key}
                          aria-selected="true">{tt.buttonName}</a>
                      </li>

                    );

                  })}

                  {!viewMode && (
                    <div className="flex flex-bottom row-reverse col ">
                      <button className="btn btn-primary ">{isEditMode ? 'Update' : 'Submit'}</button>

                    </div>)}
                </ul>

                <div className="tab-content" id="pills-tabContent">
                  {Object.values(taskTypes).map((tt) => {

                    return (

                      <div
                        className={"tab-pane fade " + (tt.active ? "active show" : "")}
                        id={"pills-" + tt.key}
                        role="tabpanel" aria-labelledby={"pills-" + tt.key}>

                        <Spec
                          key={"layout_" + tt.title}
                          title={tt.title}
                          mainItems={tt.items}
                          mainSetMethod={tt.setMethod}
                        ></Spec>
                      </div>



                    );

                  })}
                </div>





              </form>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );



}

export function Spec(props) {

  function addNewMainItem(e, title) {

    props.mainSetMethod(a => [...a, getSpec("", "", title, true)]);
    //console.log((" addNewMainItem index isSpecs : " + e);)
  }
  function getSpec(title, subtitle, mainTitle, expanded) {
    return {
      title: title,
      frequency: "DAILY",
      subItems: [getSubSpec(subtitle, mainTitle)],
      expanded: expanded,
      rowId: new Date().getTime()
    }
  }
  function getSubSpec(subtitle, mainTitle) {
    var q = {
      type: "CHECKBOX",
      frequency: "DAILY",
      mandatory: true
    };
    if (mainTitle === "Audit") {
      q = {
        type: "RATING",
        mandatory: true
      };
    }
    if (mainTitle === "Feedback") {
      q = {
        type: "RATING_10",
        mandatory: true
      };
    }
    //console.log((mainTitle, subtitle);)
    return {
      title: subtitle,
      questions: [q]
    }
  }

  return (
    <div className="row  mt-4 mb-4 ">
      <div className="col-12 col-lg-12 stretch-card">
        <div className="col-sm-12">


          <div className="row flex align-bottom mb-3">


            <Grid container  >
              <Grid item>
                <Typography variant="h5" gutterBottom component="div">
                  {props.title}
                </Typography>
              </Grid>

              <Grid item className="ml-2" >

                <button type="button" className="btn btn-outline-secondary btn-sm ml-2 btn-rounded" onClick={e => addNewMainItem(e, props.title)}>
                  {i18n.t('btn.add',"Add")}

                </button>

              </Grid>



            </Grid>










          </div>



          <div
            className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-striped animate">

                  <tbody className="tbody">
                    {props.mainItems.map((mainItem, index) => {

                      return (
                        <tr className="rounded-corners highlight-hover">

                          <MainItem
                            key={"mainItem_" + index}
                            item={mainItem}
                            index={index}
                            mainItems={props.mainItems}
                            mainSetMethod={props.mainSetMethod}
                            title={props.title}
                            getSubSpec={getSubSpec}
                          ></MainItem>


                        </tr>










                      )
                    })}


                  </tbody>

                </table>
              </div>
            </div>



          </div>

        </div>
      </div>

    </div>
  );
}

export function MainItem(props) {
  const index = props.index;
  var items = props.mainItems;
  var setMethod = props.mainSetMethod;
  useEffect(() => {
    setExpanded(props.item.expanded ? props.item.rowId : expanded);
  }, [props.item])
  function handleChange(e) {
  }
  function addNewSubItem() {


    items.forEach((item, i) => {
      if (i == index) {
        item.subItems.push(props.getSubSpec(""));
      }
    });
    setMethod([...items]);
  }

  function mainItemPosChange(pos) {

    pos = parseInt(pos) - 1;
    if (pos >= 0 && pos <= props.mainItems.length && pos != (index)) {

      var element = items[index];
      items.splice(index, 1);
      items.splice(pos, 0, element);
      i = pos + 1;
      setMethod([...items]);
    }
    //console.log(("The MAIN button text is: " + pos);)
  }
  var i = props.index + 1;
  function changeTitle(e) {
    const title = e.target.value;
    props.item.title = title;
    setMethod([...items]);
  }
  function changeDescription(e) {
    const title = e.target.value;
    props.item.description = title;
    setMethod([...items]);
  }

  function removeMainItem() {
    //console.log(('clicked');)
    items.splice(i - 1, 1);
    setMethod([...items]);
  }
  function selectFrequencty(e) {
    props.item.frequency = e.target.value;
    setMethod([...items]);
  }
  const [expanded, setExpanded] = useState(-1);

  const handleExpaandChange = (panel) => (event, newExpanded) => {
    // //console.log((panel, newExpanded);)
    setExpanded(newExpanded ? panel : 121212);
  };
  return (

    <>

      <Accordion square TransitionProps={{ unmountOnExit: true }}
        defaultExpanded={props.item.expanded}
        expanded={expanded === props.item.rowId} onChange={handleExpaandChange(props.item.rowId)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}

          aria-controls={"panel-" + props.item.rowId + "-content"}
          id={"panel-" + props.item.rowId + "-header"}
        >
          <Grid container xs={12} className="flex justify-space-between" >
            <Grid item >
              <Grid container spacing={3} >
                <Grid item spacing={3}>
                  <h6 className="mt-1">{i}.</h6>
                </Grid>
                <Grid item onClick={() => { }} >
                  {props.item.title ? props.item.title : ""}
                </Grid>
              </Grid>
            </Grid>


            <Grid item>
              <FormControlLabel
                aria-label="Acknowledge"
                onClick={(event) => event.stopPropagation()}
                onFocus={(event) => event.stopPropagation()}
                control={
                  <Grid container >
                    <Grid item>
                      <div className="col-1 flex align-top f-f-r btn-group-horizontal" role="group">

                        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">

                          <Button
                            onClick={e => mainItemPosChange(i - 1)}
                          >
                            <i className="mdi mdi-arrow-up"></i>

                          </Button>
                          <Button
                            onClick={e => mainItemPosChange(i + 1)}
                          >
                            <i className="mdi mdi-arrow-down"></i>
                          </Button>

                          {
                            (i > 0 || items.length > 1) ? (

                              <Button
                                color="secondary"
                                onClick={e => removeMainItem()}>
                                x
                              </Button>


                            ) : (<div></div>)
                          }

                        </ButtonGroup>

                      </div>

                    </Grid>


                  </Grid>
                }
              />
            </Grid>

          </Grid>


        </AccordionSummary>
        <AccordionDetails>


          <Grid container xs={12} className="row mb-2 mt-4 ">

            <div className="col-md-12 faq-section animate">
              <div className="row">



                <div className="col-md-6">
                  <label htmlFor={"mainSpectitle" + i}>    {i18n.t('jd.add.form.tasks.title',"Title")}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={"mainSpectitle" + i}
                    placeholder=    {i18n.t('jd.add.form.tasks.title.placeholder',"Title")}

                    value={props.item.title ? props.item.title : ""}
                    onChange={changeTitle}
                    required
                  />
                </div>
                <div className="col-md-4">
                  <label htmlFor={"mainSpectitle" + i}>    {i18n.t('jd.add.form.tasks.description',"Description")}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id={"mainSpecdescription" + i}
                    placeholder=    {i18n.t('jd.add.form.tasks.description.placeholder',"Description")}

                    value={props.item.description ? props.item.description : ""}
                    onChange={changeDescription}
                    required={false}
                  />
                </div>
                <div className="col-md-2">
                  <label htmlFor={"subFreq" + i}>    {i18n.t('jd.add.form.tasks.frequency',"Frequency")}
                  </label>
                  <select
                    className="form-control form-control-sm"
                    id={"subFreq" + i}
                    onChange={selectFrequencty}
                    value={props.item.frequency}
                    required
                  >
                    {
                      Object.keys(frequencies).map((key) => {
                        return (
                          <option value={key}>{frequencies[key]}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>



              <div className="row">
                <div className="col-md-12  mt-3 ml-5">
                  <div className="row flex align-bottom mb-3">

                    <Grid container  >
                      <Grid item>
                        <Typography variant="h6" gutterBottom component="div">
                          {i18n.t('jd.add.form.sub-tasks',"Sub Tasks")}

                        </Typography>
                      </Grid>

                      <Grid item className="ml-2" >

                        <button type="button" className="btn btn-outline-secondary btn-sm ml-2 btn-rounded" onClick={e => addNewSubItem()}>
                          {i18n.t('btn.add',"Add")}

                        </button>

                      </Grid>



                    </Grid>






                  </div>

                  <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                      <TableHead>
                        <TableRow>

                          <TableCell width="50%" >    {i18n.t('jd.add.form.sub-tasks.title',"Title")}
                          </TableCell>
                          <TableCell align="center">    {i18n.t('jd.add.form.sub-tasks.frequency',"Frequency")}
                          </TableCell>
                          <TableCell align="center">    {i18n.t('jd.add.form.sub-tasks.questions',"Questions")}
                          </TableCell>
                          <TableCell align="right"></TableCell>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.item.subItems.map((subItem, index) => {

                          return (

                            <SubItem
                              key={"SubItem_" + index}
                              item={subItem}
                              title={props.title}
                              index={index}

                              mainItems={props.mainItems}
                              mainSetMethod={props.mainSetMethod}

                              mainIndex={props.index}
                              items={props.item.subItems}
                            ></SubItem>

                          )

                        })}

                      </TableBody>
                    </Table>
                  </TableContainer>

                </div>

              </div>
              <hr className="solid"></hr>

            </div>


          </Grid>

        </AccordionDetails>
      </Accordion>





    </>

  );
}
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});
export function SubItem(props) {

  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();


  var items = props.mainItems;
  var setMethod = props.mainSetMethod;
  function removeSubItem() {

    items.forEach((item, i) => {
      if (i == props.mainIndex) {
        item.subItems.splice(props.index, 1);
      }
    });
    setMethod([...items]);
  }
  var i = props.index + 1;
  function handleChange(e) {
    //console.log((e);)
  }
  var pos = 0;

  useEffect(() => {
    pos = props.items.indexOf(props.item) + 1;
  }, [props.mainItems]);

  function addQuestion() {

    items.forEach((item, i) => {
      if (i == props.mainIndex) {
        item.subItems.forEach((subItem, i2) => {
          if (i2 === props.index) {
            subItem.questions.push(getQuestion(props.title))
          }
        });
      }
    });
    setMethod([...items]);
  }
  function getQuestion(title) {

    return {
      type: "TEXT",
      mandatory: false
    }
  }
  function subItemPosChange(pos) {
    const index = props.index
    pos = parseInt(pos) - 1;
    var subs = props.mainItems[props.mainIndex].subItems;
    if (pos >= 0 && pos <= subs.length && pos != (index)) {

      var element = subs[index];
      subs.splice(index, 1);
      subs.splice(pos, 0, element);
      i = pos + 1;
      props.mainItems[props.mainIndex].subItems = subs;
      setMethod([...items]);
    }
  }
  function changeTitle(e) {
    const title = e.target.value;
    props.item.title = title;
    setMethod([...items]);
  }
  function selectFrequencty(e) {
    props.item.frequency = e.target.value;
    setMethod([...items]);
  }
  function getQuestionsText() {
    const count = props.item.questions ? props.item.questions.length : 0;
    const s = count > 1 ? "s" : "";
    return count + " Question" + s;
  }
  return [(

    <TableRow className={classes.root}>
      <TableCell>
        <TextField
          type="text"
          className="form-control form-control-sm"
          id={"subSpecTitle" + i}
          placeholder="Title"
          onChange={changeTitle}
          label=    {i18n.t('jd.add.form.sub-tasks.title.placeholder',"Title")}

          value={props.item.title ? props.item.title : ""}
          required
        />
      </TableCell>

      <TableCell align="center">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="outlined-Frequency-native">Frequency</InputLabel>
          <Select
            native
            onChange={selectFrequencty}
            value={props.item.frequency}
            label=    {i18n.t('jd.add.form.sub-tasks.frequency',"Frequency")}

            inputProps={{
              name: 'Frequency',
              id: 'outlined-Frequency-native',
            }}
          >

            {
              Object.keys(frequencies).map((key) => {
                return (
                  <option value={key}>{frequencies[key]}</option>
                )
              })
            }

          </Select>
        </FormControl>
      </TableCell>


      <TableCell className="question-items" onClick={() => setOpen(!open)}>
        <Grid container className="  flex justify-space-between cursor-pointer">
          <Grid item>
            <Typography>
              {getQuestionsText()}

            </Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="expand row" size="small">
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Grid>

        </Grid>

      </TableCell>

      <TableCell align="right">
        <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
          <Button
            onClick={e => subItemPosChange(i - 1)}
          >
            <i className="mdi mdi-arrow-up"></i>

          </Button>
          <Button
            onClick={e => subItemPosChange(i + 1)}
          >
            <i className="mdi mdi-arrow-down"></i>
          </Button>
          {
            (i > 0 || items.length > 1) && (

              <Button
                color="secondary"
                onClick={e => removeSubItem()}>
                x
              </Button>

            )
          }

        </ButtonGroup>
      </TableCell>

    </TableRow>



  ), (
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>

            <Grid container  >
              <Grid item>
                <Typography variant="h6" gutterBottom component="div">
                  Questions
            </Typography>
              </Grid>

              <Grid item className="ml-2" >

                <button type="button" className="btn btn-outline-secondary btn-sm ml-2 btn-rounded" onClick={e => addQuestion()}>
                  {i18n.t('btn.add',"Add")}

                </button>

              </Grid>



            </Grid>

            <Table size="small" aria-label="purchases">
              <TableHead>
                <TableRow>
                  <TableCell width="30%">    {i18n.t('jd.add.form.question.question.type',"Question Type")}
                  </TableCell>
                  <TableCell>    {i18n.t('jd.add.form.question.question.mandatory',"Mandatory")}
                  </TableCell>
                  <TableCell width="30%">    {i18n.t('jd.add.form.question.question.additional-info',"Additional Info")}
                  </TableCell>

                  <TableCell ></TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {props.item.questions.map((question, index) => {
                  return (<Question
                    key={"Question_" + index}
                    item={question}
                    title={props.title}
                    mainItems={props.mainItems}
                    mainSetMethod={props.mainSetMethod}
                    subIndex={props.index}
                    mainIndex={props.mainIndex}
                    index={index}
                    items={props.item.questions}
                  ></Question>)
                })}
              </TableBody>
            </Table>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>)];
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }),
);
export function Question(props) {

  var items = props.mainItems;
  var title = props.title;
  var setMethod = props.mainSetMethod;
  useEffect(() => {
    items = props.mainItems;
    setMethod = props.mainSetMethod;
  }, [props.mainItems])
  function removeQuestion() {

    items.forEach((item, i) => {
      if (i == props.mainIndex) {
        item.subItems.forEach((subItem, si) => {
          if (si == props.subIndex) {
            subItem.questions.splice(props.index, 1);
          }
        })
      }
    });
    setMethod([...items]);
  }
  function changeQuestionType(e) {
    props.item.type = e.target.value;
    setMethod([...items]);
  }

  function changeMandatory(e) {
    props.item.mandatory = !props.item.mandatory;
    setMethod([...items]);
  }
  const i = props.index;
  const si = props.subIndex;
  const mi = props.mainIndex;
  const key = mi + "_" + si + "_" + i;
  const classes = useStyles();

  function changeTitle(e) {
    const title = e.target.value;
    props.item.title = title;
    setMethod([...items]);
  }
  return (

    <TableRow className="animate-height">

      <TableCell>
        <FormControl
          style={{ width: "80%" }}
          variant="outlined" className={classes.formControl}>
          <Select

            native
            labelId={key}
            value={props.item.type}
            onChange={changeQuestionType}
            inputProps={{
              name: 'Type',
              id: key,
            }}
          >
            <option value="CHECKBOX">    {i18n.t('jd.add.form.question.question-type.checkbox',"Checkbox")}
            </option>



            {title === i18n.t('jd.add.form.client-feedbacks.title',"Feedbacks") ?

              (
                <option value="RATING_10">    {i18n.t('jd.add.form.question.question-type.rating-10',"10 Star Rating")}
                </option>
              ) : (
                <option value="RATING">    {i18n.t('jd.add.form.question.question-type.rating',"Rating")}
                </option>
              )
            }

            <option value="TEXT">    {i18n.t('jd.add.form.question.question-type.text',"Text")}
            </option>
            <option value="BEFORE_IMAGES">    {i18n.t('jd.add.form.question.question-type.before-images',"Before Images")}
            </option>
            <option value="AFTER_IMAGES">    {i18n.t('jd.add.form.question.question-type.after-images',"After Images")}
            </option>
            <option value="FILE">    {i18n.t('jd.add.form.question.question-type.file',"File")}
            </option>
          </Select>
        </FormControl>
      </TableCell>

      <TableCell>
        <Checkbox

          onChange={changeMandatory}
          checked={props.item.mandatory}
          color="primary"
          inputProps={{ 'aria-label': 'secondary checkbox' }}
        />
      </TableCell>

      <TableCell>
        <TextField
          type="text"
          className="form-control "
          id={"additionalInfo_" + i}
          placeholder="Optional"
          onChange={changeTitle}
          label=    {i18n.t('jd.add.form.question.question.additional-info.placeholder',"Info")}

          value={props.item.title ? props.item.title : ""}
        />
      </TableCell>



      <TableCell align="right">
        {
          (i > 0) && (

            <Button
              color="secondary"
              onClick={e => removeQuestion()}>
              x
            </Button>

          )
        }
      </TableCell>

    </TableRow>



  );


}

