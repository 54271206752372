import { PageLayout } from "../../../components/pageLayout";
import { ReportFilter } from "./shared/filter";
import React, { useState, useEffect, setState, createRef } from "react";
import { downloadPayrollReport, getSitePayroll, getWork, getWorkSummary, workSummaryEdit } from "../../../api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { formatDate } from "../../../utils/dateUtils";
import { store } from "../../../state/store";
import { InspectionReport } from "./detailedWorkReport";
import { date2str } from "../../../utils/dateUtils"
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { makeStyles } from '@material-ui/core/styles';
import { WorkSummaryEdit } from "./shared/workSummaryEdit";
import { filterJobLocationsForGroupsById } from "../../../utils/objectFilter"
import Button from "@material-ui/core/Button";
import {Menu, MenuItem} from "@material-ui/core";
import i18n from "i18next";


const statuses = {
    "Completed": "COMPLETED",
    "Ongoing": "STARTED",
    "On hold": "ON_HOLD",
}
const specTypes = {
    "Inspections": "INSPECTION",
    "Tasks": "NORMAL",
    "All": "ALL"
}

export function SitePayroll() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);

    const allLocationIds = useSelector((state) => state.locations.byId);


    const [LocationsById, setLocationsById] = useState({...allLocationIds});
    const [selectedSpec, setSelectedSpec] = useState("");
    const [selectedSite, setSelectedSite] = useState(-1);
    const [payroll, setPayroll] = useState({});

    const allGroupData = useSelector((state) => state.groups.byId);
    const [groups, setGroups] = useState([]);


    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    useEffect(() => {
        setSelectedSpec('Inspections')
    }, [])
    async function onSiteChange(e) {
        setSelectedSite(e.target.value);
    }

    async function onDateRangeChange(st, en) {
        setDates({ start: st, end: en });
    }
    async function _loadReport() {
        let error = "";
        if (selectedSite == -1) {
            error = 'Please select a site.';
        }

        if (error === '') {
            const res = await getSitePayroll(companyState.id, selectedSite, formatDate(dates.start), formatDate(dates.end));
            if (res && res.data) {
                setPayroll(res.data);
            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }
    async function downloadPdf(reportType) {
        if (reportType == null) {
            reportType='CSV';
        }
        let error = "";
        if (selectedSite == -1) {
            error = 'Please select a site.';
        }

        if (error === '') {
            const res = await downloadPayrollReport("LOCATION", companyState.id, null, selectedSite, formatDate(dates.start), formatDate(dates.end),reportType);

            if (res && res.data) {

                let binaryString = window.atob(res.data);
                let binaryLen = binaryString.length;

                let bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    let ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                let blob = new Blob([bytes], { type: reportType=='PDF'?"application/pdf":'text/csv' });

                let link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                link.download = allLocationIds[selectedSite].name + "."+reportType.toLowerCase();

                link.click();

            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }

    function _setGroups(groups) {

        if(groups && groups.length>0){
            const filteredById = filterJobLocationsForGroupsById(Object.values(allLocationIds), groups);
            setLocationsById({ ...filteredById });
            
        }else{
            setLocationsById({ ...allLocationIds });

        }
        setGroups(groups);
    }


    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">    {i18n.t('reports.site-payroll.title',"Site Payroll Report")}
                            </h4>
                            <p className="card-description">    {i18n.t('reports.site-payroll.description',"Generates payroll report a job location")}
                            </p>

                            <div className="row">
                                <div className="mt-2 mb-2 filter col-sm-12">
                                    <ReportFilter
                                        key="reportFilter2"
                                        onSiteChange={onSiteChange}
                                        onDateRangeChange={onDateRangeChange}
                                        allLocationIds={LocationsById}
                                        locationFilter={true}
                                        start={dates.start}
                                        end={dates.end}
                                        groupSelection={allGroupData && Object.keys(allGroupData).length>0}
                                        groups={groups}
                                        setGroups={_setGroups}
                                        allGroups={Object.values(allGroupData)}
                                  >
                                    </ReportFilter>
                                </div>
                            </div>



                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                        onClick={(e) => _loadReport()}
                                        className="btn btn-primary btn-lg">    {i18n.t('btn.generate',"Generate")}
                                    </button>

                                </div>
                            </div>





                        </div>
                    </div>

                </div>
                {
                    payroll && payroll.payrollType && (
                        <div className="row">
                            <div className="col-sm-12">
                                <PayrollReport
                                    key="sitePayrollReport"
                                    payroll={payroll}
                                    selectedSite={selectedSite}
                                    downloadPdf={downloadPdf}
                                    _loadReport={_loadReport}
                                >
                                </PayrollReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}


export function PayrollReport(props) {
    const ref = createRef();
    const payroll = props.payroll;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    function getFileName() {
        return "selectedSpec.pdf";
    }
    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">    {i18n.t('reports.site-payroll.report.title',"Report")}
                            </h4>
                            <Button aria-controls="simple-menu"   className="btn btn-primary" variant="contained"  color="success"
                                    size="large"  aria-haspopup="true" onClick={handleClick}>
                                {i18n.t('btn.download',"Download")}

                            </Button>
                            <Menu
                                id="simple-menu"


                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={()=>props.downloadPdf('PDF')}>    {i18n.t('report.download-type.pdf',"As PDF")}
                                </MenuItem>
                                <MenuItem onClick={()=>props.downloadPdf('CSV')}> {i18n.t('report.download-type.csv',"As CSV")}
                                </MenuItem>
                            </Menu>

                        </div>

                        <Report
                            selectedSite={props.selectedSite}
                            payroll={payroll}
                            _loadReport={props._loadReport}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

export function Report(props) {
    const payroll = props.payroll;
    const allLocationIds = useSelector((state) => state.locations.byId);
    const allClientData = useSelector((state) => state.users.byId);
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    var totalOnBreakTime = moment.duration(payroll.totalOnBreakTime);
    var totalServiceTime = moment.duration(payroll.totalServiceTime);
    var totalDailyQuote = moment.duration(payroll.totalDailyQuote);

    const [open, setOpen] = useState(false);
    const [summaryData, setSummaryData] = useState({});
    const [workId, setWorkId] = useState(-1);

    const useStyles = makeStyles(theme => ({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: "80%",
            margin: "auto"
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
    }));
    const classes = useStyles();
    function getClassname(success) {
        return success ? "display-4 text-five" : "display-4 text-one";
    }
    return (
        <div if="pdf">



            <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>

            <Modal
                open={open}
                className={classes.modal}
                onClose={() => { setOpen(false) }}
                closeAfterTransition

                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <WorkSummaryEdit
                        companyId={companyState.id}
                        workId={workId}
                        setOpen={setOpen}
                        _loadReport={props._loadReport}
                    >

                    </WorkSummaryEdit>
                </Fade>

            </Modal>


            <div className="row">
                <div className="col-sm-12 mt-5">
                    <ReportBasiicInfo
                        payroll={props.payroll}
                        selectedSite={props.selectedSite}
                    >
                    </ReportBasiicInfo>
                </div>
            </div>

            {
                payroll && payroll.payrollDetail &&

                (
                    <>
                        <div className="col-lg-12 mt-4">
                            <table className="table-custom">
                                <thead>
                                    <tr>
                                        <th>    {i18n.t('report.header.date',"Date")}
                                        </th>
                                        <th>    {i18n.t('report.header.emp-id',"Employee ID")}
                                        </th>
                                        <th>    {i18n.t('report.header.emp-name',"Employee Name")}
                                        </th>
                                        <th>    {i18n.t('report.header.breaks',"Breaks")}
                                        </th>
                                        <th>    {i18n.t('report.header.on-break',"On Break")}
                                        </th>
                                        <th>    {i18n.t('report.header.clock-in',"Clock in")}
                                        </th>
                                        <th>    {i18n.t('report.header.clock-out',"Clock out")}
                                        </th>
                                        <th>    {i18n.t('report.header.service-time',"Service time")}
                                        </th>
                                        <th>    {i18n.t('report.header.daily-quote',"Daily Quote")}
                                        </th>
                                        <th>    {i18n.t('report.header.actions',"Actions")}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(payroll.payrollDetail).map(
                                            (item) => {
                                                return (
                                                    <PayrollItem
                                                        allLocationIds={allLocationIds}
                                                        allClientData={allClientData}
                                                        item={item}
                                                        setOpen={setOpen}
                                                        setWorkId={setWorkId}
                                                    />

                                                )
                                            }
                                        )
                                    }
                                </tbody>
                                <tfoot className="border-top border-bottom">
                                    <tr>
                                        <td colSpan="4" className="font-weight-bold" style={{ textAlign: "end", paddingRight: "20px" }}>    {i18n.t('report.footer.total',"Total")}
                                        </td>
                                        <td className="font-weight-bold">{getDuration(payroll.totalOnBreakTime)}</td>
                                        <td ></td>
                                        <td ></td>
                                        <td className="font-weight-bold">{getDuration(payroll.totalServiceTime, true)}</td>
                                        <td className="font-weight-bold">{getDuration(payroll.totalDailyQuote, true)}</td>
                                    </tr>

                                </tfoot>

                            </table>

                        </div>

                        <div className="row mt-4 flex justify-content-end">
                            <div className="col-lg-6 mt-4 ">
                                <div className="row ">

                                    <div className="col-6 flex justify-content-center"><p className="display-4">    {i18n.t('report.footer.variance',"Variance (Quota Vs Actual)")}
                                    </p></div>
                                    <div className="col-1"><p className="display-4">:</p></div>
                                    <div className="col-5"><p className={getClassname(payroll.totalServiceTime > payroll.totalDailyQuote)}>{getDuration(payroll.totalServiceTime - payroll.totalDailyQuote)}</p></div>
                                </div>

                            </div>

                        </div>




                    </>


                )

            }

        </div>
    )
}

const getDuration = (time) => {
    if (!time) {
        time = 0;
    }
    const duration = moment.duration(time);

    const h = Math.floor(duration.asHours());
    const m = duration.minutes();
    const s = duration.seconds();

    const hr = h === 0 ? "00: " : ((h < 10 ? "0" + h : h) + ": ");
    const mi = m === 0 ? "00: " : ((m < 10 ? "0" + m : m) + ": ");
    const sr = s === 0 ? "00" : (s < 10 ? "0" + s : s);

    return hr + "" + mi + "" + sr;

}


export async function _viewWork(workid) {

    if (workid && workid != -1) {
        const res = await getWork(workid, null);
        if (res.success) {


            let wrapper = document.createElement('div');
            wrapper.className = "text-align-left"
            ReactDOM.render(
                <Provider store={store}>
                    <InspectionReport
                        key="inspectinReport"
                        selectedWork={res.data}
                        selectedSpec={"ALL"}
                        popup={true}
                    >
                    </InspectionReport>
                </Provider>



                , wrapper);
            let el = wrapper.firstChild;
            swal({
                title: 'Work detail',
                html: true,
                type: "info",
                className: 'swal-wide',
                content: el,
                showCancelButton: true,
                showConfirmButton: false
            });
        }
        else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }


}

export function PayrollItem(props) {
    const item = props.item;
    const payroll = props.item;
    const allClientData = props.allClientData;
    const allLocationIds = props.allLocationIds;
    const user = allClientData[item.tenantUserId];
    const large = moment(payroll.clockOut).date() !== moment(payroll.clockIn).date();



    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    async function _editWork(workid) {

        if (workid && workid != -1) {
            props.setWorkId(workid);
            props.setOpen(true);

        }


    }
    return (
        <tr >
            <td>{moment(payroll.date).format('DD/MM/YYYY')}</td>
            <td>{user.employeeNumber}</td>
            <td>{user.user.firstName + " " + user.user.lastName}</td>
            <td >{item.breakCount}</td>
            <td >{getDuration(item.breakTime)}</td>
            <td>{moment(payroll.clockIn).format('HH:mm:ss')}</td>
            <td>{
                moment(payroll.clockOut).format('HH:mm:ss')}{large && (<span className="text-sm">{moment(payroll.clockOut).format('(Do)')}</span>)}
            </td>
            <td >{getDuration(item.serviceTime)}</td>
            <td >{getDuration(item.dailyQuote)}</td>
            <td>
                <div className="btn-action ">
                    <button className={(item.editHistory > 0) ? "btn btn-sm  btn-fw  btn-outline-success" : "btn btn-sm  btn-fw  btn-outline-primary"}

                        onClick={() => { _editWork(item.workId) }}
                    ><i className="mdi mdi-pencil"></i></button>
                    <button className="btn btn-sm btn-outline-primary btn-fw"

                        onClick={() => { _viewWork(item.workId) }}
                    ><i className="mdi mdi-eye"></i></button>
                </div>

            </td>


        </tr>
    );
}

export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const payroll = props.payroll;
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);

    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }
    useEffect(() => {
        const client = allClientData[payroll.clientId];
        const location = allLocationIds[props.selectedSite];
        const map = {};

        map['Client'] = getObj(client.user.firstName + " " + client.user.lastName, 3);
        map['Site Address'] = getObj(location.address, 3);
        setDmap(map);
    }, [])
    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>
                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">    {i18n.t('report.started-date',"Started Date")}
                                </td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.startTime).format('MMMM D, YYYY')}</td>

                                <td className="font-weight-bold p-0  pl-2">    {i18n.t('report.started-time',"Started Time")}
                                </td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.startTime).format('HH:mm:SS')}</td>
                            </tr>

                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">    {i18n.t('report.end-date',"End Date")}
                                </td>
                                <td className="pl-2 p-0 pb-0 pt-1 ">{moment(payroll.endTime).format('MMMM D, YYYY')}</td>

                                <td className="font-weight-bold p-0 pb-0  pl-2">    {i18n.t('report.end-time',"End Time")}
                                </td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(payroll.endTime).format('HH:mm:SS')}</td>
                            </tr>
                            {
                                Object.keys(dMap).map((key) => {
                                    return (<tr>
                                        <td className="font-weight-bold p-0 pb-0  pr-2">{key}</td>
                                        <td className="pl-2 p-0 pb-0 pt-1" colSpan={dMap[key].span}>{dMap[key].value} </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


