import { PageLayout } from "../../../components/pageLayout";
import { ReportFilter } from "./shared/filter";
import React, { useState, useEffect, setState, createRef } from "react";
import { getSitePayroll, getJobs, getInspectionRatingTrend } from "../../../api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { formatDate } from "../../../utils/dateUtils";
import { store } from "../../../state/store";
import { Bar, Line } from "react-chartjs-2";
import { trim } from "jquery";


const statuses = {
    "Completed": "COMPLETED",
    "Ongoing": "STARTED",
    "On hold": "ON_HOLD",
}
const specTypes = {
    "Inspections": "INSPECTION",
    "Tasks": "NORMAL",
    "All": "ALL"
}

export function InspectionTrend() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);

    const allLocationIds = useSelector((state) => state.locations.byId);


    const [jobs, setJobs] = useState([]);
    const [selectedSite, setSelectedSite] = useState(-1);
    const [selectedJob, setSelectedJob] = useState(-1);
    const [trendItems, setTrendItems] = useState({});

    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    useEffect(() => {
        setSelectedSite(-1);
    }, [])
    async function onSiteChange(e) {
        const id = e.target.value;
        setSelectedSite(id);
        const res = await getJobs(companyState.id, null, id, null, null, null);
        if (res && res.success) {
            setJobs(res.data);
            if(res.data&&res.data[0]){
                setSelectedJob(res.data[0].id);
            }
        } else {
            swal(
                {
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                }
            );
        }
    }

    async function onDateRangeChange(st, en) {
        setDates({ start: st, end: en });
    }
    async function _loadReport() {
        let error = "";
        if (selectedJob == -1) {
            error = 'Please select a job.';
        }

        if (error === '') {
            const res = await getInspectionRatingTrend(selectedJob);
            if (res && res.data && res.data.trendItemsMap) {
                setTrendItems({ ...res.data.trendItemsMap });
            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }




    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Inspection Report</h4>
                            <p className="card-description">Generates inspection report</p>

                            <div className="row mb-4">

                                <div className="col-md-6 col-lg-4" id="sites-range">

                                    <label htmlFor="site-filter">Site</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="site-filter"
                                        required
                                        onChange={(e) => onSiteChange(e)}
                                    >
                                        <option value="">Select Site...</option>
                                        {allLocationIds &&
                                            Object.values(allLocationIds).map((site) => {
                                                return (
                                                    <option value={site.id} key={site.id}>
                                                        {site.id + ". " + site.name}
                                                    </option>
                                                );

                                            })}
                                    </select>
                                </div>

                                <div className="col-md-6 col-lg-4" id="jobs-range">

                                    <label htmlFor="job-filter">Job</label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="job-filter"
                                        required
                                        value={selectedJob}
                                        onChange={(e) => setSelectedJob(e.target.value)}
                                    >
                                        <option value="">Select Job...</option>
                                        {jobs &&
                                            jobs.map((job) => {
                                                return (
                                                    <option value={job.id} key={job.id}>
                                                        {'Job ID : ' + job.id}
                                                    </option>
                                                );

                                            })}
                                    </select>
                                </div>
                            </div>



                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                        onClick={(e) => _loadReport()}
                                        className="btn btn-primary btn-lg">Generate</button>

                                </div>
                            </div>





                        </div>
                    </div>

                </div>
                {
                   selectedJob != -1&&  trendItems && selectedSite != -1 && (
                        <div className="row">
                            <div className="col-sm-12">
                                <InspectionTrendReport
                                    key="inspection"
                                    trendItems={trendItems}
                                    selectedSite={selectedSite}
                                >
                                </InspectionTrendReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}


export function InspectionTrendReport(props) {
    const ref = createRef();
    const trendItems = props.trendItems;


    function getFileName() {
        return "selectedSpec.pdf";
    }
    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };

    function downloadPdf() {
        let wrapper = document.createElement('div', {
            style: {
                width: "2100px",
                height: "2970px"
            }
        });

        ReactDOM.render(
            <Provider store={store}>
                <Report
                    payroll={trendItems}
                ></Report>
            </Provider>
            ,
            wrapper
        );
        swal({
            html: true,
            className: 'swal-pdf',
            content: wrapper,
            showCancelButton: true,
            showConfirmButton: false,
            timer: 500
        }).then(() => {

        });
    }
    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">Report</h4>
                            <ReactToPdf targetRef={ref} filename={getFileName()} options={options} x={.4} y={-2} scale={0.6}>
                                {({ toPdf }) => (
                                    <button onClick={() => downloadPdf(toPdf)} className="btn btn-info ">Download</button>
                                )}
                            </ReactToPdf>
                        </div>

                        <Report
                            selectedSite={props.selectedSite}
                            trendItems={trendItems}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

export function Report(props) {
    const trendItems = props.trendItems;

    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    function getClassname(success) {
        return success ? "display-4 text-five" : "display-4 text-one";
    }
    return (
        <div if="pdf">



            <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>



            <div className="row">
                <div className="col-sm-12 mt-5 mb-3">
                    <ReportBasiicInfo
                        payroll={props.payroll}
                        selectedSite={props.selectedSite}
                    >
                    </ReportBasiicInfo>
                </div>
            </div>
            {/* title */}

            {
                trendItems &&

                (
                    <>
                        {
                            Object.keys(trendItems).map((key) => {
                                return (
                                    <div className="row mt-2">
                                        <div className="col">
                                            <div className="row">
                                                <p className="display-5 col">{key}</p>

                                            </div>
                                            <div className="col-12">
                                                <div className="table-responsive">
                                                    <div className="table table-striped">
                                                        <div className="row ">
                                                            {
                                                                trendItems[key].map((trendItem) => {
                                                                    return (

                                                                        <TrendItem
                                                                            trendItem={trendItem}
                                                                        ></TrendItem>

                                                                    );
                                                                })
                                                            }
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    </div>

                                );
                            })
                        }






                    </>


                )

            }

        </div>
    )
}


export function TrendItem(props) {
    const trendItem = props.trendItem;


    function emptyData() {
        var data = {
            labels: [],
            datasets: [
                {
                    label: props.label,
                    data: [],
                    fill: true,
                    backgroundColor: getBGColors(1),
                    borderColor: getBordersColors(1),
                },
            ],
        }
        return data;
    }

    function getData(single, item) {
        let num = 5;
        if (single) {
            num = Math.floor(Math.random() * 5);
        }
        if (!item) {
            return emptyData();
        }
        var data = {
            labels: Object.keys(item),
            datasets: [
                {
                    label: props.label,
                    data: Object.values(item),
                    fill: true,
                    backgroundColor: getBGColors(2),
                    borderColor: getBordersColors(2),
                    borderWidth: 2,
                },
            ],
        }
        return data;
    }
    const options = {

        responsive:true,
        maintainAspectRatio:false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
            xAxes: [{
                ticks: {
                    display: false
                }
            }]
        },
        elements: {
            line: {
                tension: 0.3
            }
        },
        legend: false
    }
    return (
        <div className="mb-3 mt-1 col-md-6">
            <div className="row">

                <div className="col-sm-12">
                    <div className="row">
                        <div className="col-8">
                            {trendItem.subTitle}
                        </div>
                        <div className="col-4 flex justify-content-end">
                            {"Average : "+trendItem.average.toFixed(2)}
                        </div>
                    </div>


                </div>




            </div>
            <div className="row">
                <div className="col-12 streched-card">
                    <Line data={getData(true, trendItem.trendMap)}
                        options={options}
                    ></Line>
                </div>
            </div>
        </div>

    );

}



export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const payroll = props.payroll;
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);

    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }
    useEffect(() => {
        const location = allLocationIds[props.selectedSite];
        const map = {};

        map['Site'] = getObj(location.name, 3);
        map['Site Address'] = getObj(location.address, 3);
        setDmap(map);
    }, [])
    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>

                            {
                                Object.keys(dMap).map((key) => {
                                    return (<tr>
                                        <td className="font-weight-bold p-0 pb-0  pr-2">{key}</td>
                                        <td className="pl-2 p-0 pb-0 pt-1" colSpan={dMap[key].span}>{dMap[key].value} </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


export function getBGColors(count, isSingle) {
    let colors = [
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(188, 185, 20,0.2)',
        'rgba(35, 8, 201,0.2)',
        'rgba(95, 125, 83,0.2)',
        'rgba(15, 75, 173,0.2)',
        'rgba(102, 43, 52,0.2)',
        'rgba(24, 182, 41,0.2)',
        'rgba(31, 206, 241,0.2)',
        'rgba(118, 94, 180,0.2)',
        'rgba(198, 251, 254,0.2)',
        'rgba(241, 206, 118,0.2)',

    ];
    if (isSingle) {
        return colors[count - 1];
    }
    return colors.slice(0, count - 1);
}

export function getBordersColors(count, isSingle) {
    let colors = [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(188, 185, 20,1)',
        'rgba(35, 8, 201,1)',
        'rgba(95, 125, 83,1)',
        'rgba(15, 75, 173,1)',
        'rgba(102, 43, 52,1)',
        'rgba(24, 182, 41,1)',
        'rgba(31, 206, 241,1)',
        'rgba(118, 94, 180,1)',
        'rgba(198, 251, 254,1)',
        'rgba(241, 206, 118,1)',
    ];
    if (isSingle) {
        return colors[count - 1];
    }
    return colors.slice(0, count - 1);
}