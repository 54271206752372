import { createSlice } from "@reduxjs/toolkit";
import { COMPANY_STATUS } from "../config";

const initState = {
  profile: [],
};

const companySlice = createSlice({
  name: "company",
  initialState: initState,
  reducers: {
    // setCompanies(state, { action, payload }) {
    //   for (var company of payload) {
    //     const { rooms, ...rest } = company;
    //     if (!(company.id in state.byId)) {
    //       state.byId[company.id] = rest;
    //       state.allIds.push(company.id);
    //     }
    //   }
    // },
    setCompany(state, { action, payload }) {
      state.profile = payload;
    },
    setCompanySettings(state, { action, payload }) {
      state.settings = payload;
    },
    // removeCompany(state, { action, payload }) {
    //   state.allIds = state.allIds.filter((id) => id !== payload.id);
    //   delete state.byId[payload.id];
    // },
  },
});

export const companyActions = companySlice.actions;
export const companyReducer = companySlice.reducer;

// export function getCompanies() {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.getCompanies();
//       dispatch(companyActions.setCompanies(response.data));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error getting the company list"));
//     }
//   };
// }

export function saveCompanyData(companyData) {
  return (dispatch, getState) => {
    try {
      dispatch(companyActions.setCompany(companyData));
      sessionStorage.setItem(COMPANY_STATUS, true);
    } catch (e) {
      // dispatch(showToastError("Error", "Worng Company Name"));
      //console.log(("Error");)
      sessionStorage.setItem(COMPANY_STATUS, false);
    }
  };
}
export function saveCompanySettings(companyData) {
  return (dispatch, getState) => {
    try {
      dispatch(companyActions.setCompanySettings(companyData));
      sessionStorage.setItem(COMPANY_STATUS, true);
    } catch (e) {
      // dispatch(showToastError("Error", "Worng Company Name"));
      //console.log(("Error");)
      sessionStorage.setItem(COMPANY_STATUS, false);
    }
  };
}
