
let count=0;
export function showLoader(){
    count++;
    document.getElementById("main-loader").style.display = "inline";
}

export function hideLoader(){
    count--;
    if(count<1 && document.getElementById("main-loader")){
        document.getElementById("main-loader").style.display = "none";
    }
}


export function showAuthLoader(){
    document.getElementById("auth-loader").style.display = "inline";
}

export function hideAuthLoader(){
    if( document.getElementById("auth-loader")){
        document.getElementById("auth-loader").style.display = "none";
    }
}