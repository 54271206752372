import {PageLayout} from "../../../components/pageLayout";
import {ReportFilter} from "./shared/filter";
import React, {createRef, useEffect, useState} from "react";
import {getWork, getWorks} from "../../../api";
import {Provider, useSelector} from "react-redux";
import swal from "sweetalert";
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import ReactDOM from 'react-dom';
import {formatDate} from "../../../utils/dateUtils";
import {store} from "../../../state/store";
import i18n from "i18next";


const statuses = {
    "Completed": "COMPLETED",
    "Ongoing": "STARTED",
    "On hold": "ON_HOLD",
}
const statusesNames = {
    "Completed": i18n.t('forms.status.completed', "Completed"),
    "Ongoing": i18n.t('forms.status.ongoing', "Ongoing"),
    "On hold": i18n.t('forms.status.on-hold', "On hold"),
}
const specTypes = {
    "Inspections": "INSPECTION",
    "Feedbacks": "FEEDBACK",
    "Tasks": "NORMAL",
    "All": "ALL"
}
const specTypesNames = {
    "Inspections": i18n.t('forms.type.inspections', "Inspections"),
    "Feedbacks": i18n.t('forms.type.feedbacks', "Feedbacks"),
    "Tasks": i18n.t('forms.type.tasks', "Tasks"),
    "All": i18n.t('forms.type.all', "All")
}
const specTypesTitle = {
    "INSPECTION": i18n.t('forms.completed-task.type.inspection', "Audit  done"),
    "FEEDBACK": i18n.t('forms.completed-task.type.feedback', "Feedback given"),
    "NORMAL": i18n.t('forms.completed-task.type.task', "Task Completed"),
    "ALL": i18n.t('forms.completed-task.type.task', "Task Completed")
}

export function Inspections() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);
    const allLocationIds = useSelector((state) => state.locations.byId);
    const [works, setWorks] = useState([]);
    const [selectedWork, setSelectedWork] = useState({});
    const [selectedWorkId, setSelectedWorkId] = useState(-1);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedSpec, setSelectedSpec] = useState("");
    const [selectedSite, setSelectedSite] = useState(-1);

    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    useEffect(() => {
        setSelectedStatus("COMPLETED");
        setSelectedSpec('Inspections')
    }, [])

    async function onSiteChange(e) {
        const id = e.target.value;
        if (id && id != -1 && dates.start && dates.end && selectedStatus) {
            _loadWorks(id, dates.start, dates.end, selectedStatus);

        }

        setSelectedSite(e.target.value);

    }

    async function onDateRangeChange(st, en) {
        setDates({start: st, end: en});
        if (selectedSite != -1 && st && en && selectedStatus) {
            _loadWorks(selectedSite, st, en, selectedStatus);

        }


    }

    async function changeStatus(stat) {
        setSelectedStatus(statuses[stat]);
        if (selectedSite != -1 && dates.start && dates.end && statuses[stat]) {
            _loadWorks(selectedSite, dates.start, dates.end, statuses[stat]);

        }

    }

    const onWorkChange = (e) => {

        setSelectedWorkId(e.target.value);

    }

    async function _loadWork() {
        const spec = (!selectedSpec || selectedSpec === 'All') ? null : specTypes[selectedSpec];
        if (selectedWorkId && selectedWorkId != -1) {
            const id = selectedWorkId;

            const res = await getWork(id, spec);
            if (res.success) {
                setSelectedWork({...res.data});
            } else {
                swal({
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                });
            }
        } else {
            swal(
                "Please select a work to generate report"
            );
        }

    }

    async function _loadWorks(site, startDate, endDate, status, spec) {
        if (!spec) {
            spec = specTypes[selectedSpec];
        }
        spec = spec == 'ALL' ? null : spec;

        //console.log((companyState.id, site, formatDate(startDate), formatDate(endDate), status);)
        const res = await getWorks(companyState.id, site, formatDate(startDate), formatDate(endDate), status, spec);
        if (res.success) {
            const _works = res.data;
            if (_works) {
                _works.forEach((work) => {
                    const user = allClientData[work.tenantUserId];
                    work['display'] = (spec ? specTypesTitle[spec] : "Task completed") + " by " + " (" + user.userRole.name + ") " + user.user.firstName + " " +
                        user.user.lastName + " on "
                        + (work.endTime ? moment(work.endTime).format('MMMM D, YYYY, HH:mm') : moment(work.startTime).format('MMMM D, YYYY, HH:mm'));

                })

            }
            setWorks([..._works]);
        } else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }

    async function changeSpecType(spec) {
        setSelectedSpec(spec);
        if (selectedSite != -1 && dates.start && dates.end && selectedStatus) {
            _loadWorks(selectedSite, dates.start, dates.end, selectedStatus, specTypes[spec]);

        }
    }


    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">Inspection Report</h4>
                            <p className="card-description">Generates inspection report</p>

                            <div className="row">
                                <div className="mt-2 mb-2 filter col-sm-12">
                                    <ReportFilter
                                        key="reportFilter1"
                                        onSiteChange={onSiteChange}
                                        onDateRangeChange={onDateRangeChange}
                                        selectedStatus={selectedStatus}
                                        statuses={statuses}
                                        start={dates.start}
                                        end={dates.end}
                                        jobStatus={true}
                                        locationFilter={true}
                                        allLocationIds={allLocationIds}
                                        changeStatus={changeStatus}
                                    >
                                    </ReportFilter>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="jobdetail_select"><h6>    {i18n.t('forms.work', "Completed works")}
                                    </h6></label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="jobdetail_select"
                                        onChange={(e) => onWorkChange(e)}
                                        required

                                    >
                                        <option value="-1">    {i18n.t('forms.work.select', "Select work...")}
                                        </option>
                                        {works &&
                                        works.map((work) => {
                                            return (
                                                <option value={work.id} key={work.id}>
                                                    {work.id + ". " + work.display}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="date-filter">    {i18n.t('forms.type', "Type")}
                                    </label>

                                    <ul
                                        className="nav nav-pills nav-pills-custom justify-content-md-start "
                                        id="pills-tab-custom"
                                        role="tablist"
                                    >
                                        {Object.keys(specTypes).map((spec) => {
                                            return (
                                                <li className="nav-item ">
                                                    <a
                                                        className={"nav-link " + (spec == selectedSpec ? "active" : "")}
                                                        id="pills-home-tab-custom"
                                                        data-toggle="pill"
                                                        href="#pills-health"
                                                        role="tab"
                                                        aria-controls="pills-home"
                                                        aria-selected={spec === 'Inspections' ? "true" : "false"}
                                                        onClick={(e) => {
                                                            changeSpecType(spec)
                                                        }}
                                                    >
                                                        {specTypesNames[spec] ? specTypesNames[spec] : spec}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                            onClick={(e) => _loadWork()}
                                            className="btn btn-primary btn-lg">    {i18n.t('btn.generate', "Generate")}

                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                {
                    selectedWork && selectedWork.tenantId && (
                        <div className="row">
                            <div className="col-sm-12">
                                <InspectionReport
                                    key="inspectinReport"
                                    selectedWork={selectedWork}
                                    selectedSpec={selectedSpec}
                                >
                                </InspectionReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}

function getAvg(avg) {

    return Math.ceil((avg / avgFactor) * 100) + "%";
}

function getOverallRating(inspections) {
    let count = 0;
    let average = 0.0;
    inspections.forEach(
        (spec) => {
            if (spec.averageRating) {
                average += spec.averageRating;
                count++;
            }
        }
    )
    return count == 0 ? 0 : (average / count);
}


export function InspectionReport(props) {
    const ref = createRef();
    const selectedWork = props.selectedWork;
    const selectedSpec = props.selectedSpec;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    function getFileName() {
        return "selectedSpec.pdf";
    }

    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };

    function downloadPdf() {
        let wrapper = document.createElement('div', {
            style: {
                width: "2100px",
                height: "2970px"
            }
        });

        ReactDOM.render(
            <Provider store={store}>
                <Report
                    selectedWork={selectedWork}
                    selectedSpec={selectedSpec}
                ></Report>
            </Provider>
            ,
            wrapper
        );
        swal({
            html: true,
            className: 'swal-pdf',
            content: wrapper,
            showCancelButton: true,
            showConfirmButton: false,
            timer: 500
        }).then(() => {

        });
    }

    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">    {i18n.t('reports.inspections.title', "Inspection Report")}
                            </h4>
                            <ReactToPdf targetRef={ref} filename={getFileName()} options={options} x={.4} y={-2}
                                        scale={0.6}>
                                {({toPdf}) => (
                                    <button onClick={() => downloadPdf(toPdf)}
                                            className="btn btn-info ">    {i18n.t('btn.download', "Download")}
                                    </button>
                                )}
                            </ReactToPdf>
                        </div>

                        <Report
                            selectedSpec={selectedSpec}
                            selectedWork={selectedWork}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

let avgFactor = 5.0;

export function Report(props) {
    const selectedWork = props.selectedWork;
    const selectedSpec = props.selectedSpec;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    const [overallAvg, setOverallAvg] = useState(0);
    useEffect(
        () => {
            let spec = specTypes[selectedSpec];
            spec = ratingArray.indexOf(spec) != -1 ? spec : 'INSPECTION';
            avgFactor = spec == 'FEEDBACK' ? 10.0 : 5.0;
            const avg = getOverallRating(selectedWork && selectedWork.jobSpecTypeListMap &&
                selectedWork.jobSpecTypeListMap[spec] ? selectedWork.jobSpecTypeListMap[spec] : [])
            ;
            setOverallAvg(avg);
        }
    );
    return (
        <div if="pdf">


            <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-12 mt-5">
                    <ReportBasiicInfo
                        selectedWork={props.selectedWork}
                    >
                    </ReportBasiicInfo>
                </div>
            </div>

            <div className="row">
                <div className="col-sm-12 col-md-10 mt-3 ">
                    <div className="table-responsive">

                        <table className="table-custom">
                            <tbody>
                            <tr>
                                <td className="font-weight-bold p-0">    {i18n.t('reports.inspections.report.legend', "Legend")}
                                </td>
                                <td className="font-weight-bold p-0 text-one">   {i18n.t('reports.inspections.report.legend.1', "1 = Unsatisfactory")}
                                </td>
                                <td className="font-weight-bold p-0  text-two">    {i18n.t('reports.inspections.report.legend.2', "2 = Fair")}

                                </td>
                                <td className=" font-weight-bold p-0  text-three">    {i18n.t('reports.inspections.report.legend.3', "3 = Good")}
                                </td>
                                <td className=" font-weight-bold p-0 text-four">    {i18n.t('reports.inspections.report.legend.4', "4 = Very Good")}
                                </td>
                                <td className=" font-weight-bold p-0 text-five">    {i18n.t('reports.inspections.report.legend.5', "5 = Excellent")}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>


            {
                selectedWork && selectedWork.jobSpecTypeListMap &&

                (
                    <>
                        {
                            Object.keys(selectedWork.jobSpecTypeListMap).map(
                                (key) => {
                                    return (
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <h3 className="card-title">{key === 'INSPECTION' ? 'Inspections' : 'Tasks'}</h3>

                                                <div className="row">
                                                    <div className="col-lg-12 mt-4">
                                                        <table className="table-custom">
                                                            {
                                                                selectedWork.jobSpecTypeListMap[key].map(
                                                                    (spec) => {
                                                                        return (<AnsweredSpec
                                                                                spec={spec}
                                                                            />

                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }


                        <div className="row mt-4 flex justify-content-end">
                            <div className="col-lg-6 mt-4 ">
                                <div className="row ">

                                    <div className="col-6 flex justify-content-center"><p className="display-4">Overall
                                        Rating</p></div>
                                    <div className="col-1"><p className="display-4">:</p></div>
                                    <div className="col-5"><p
                                        className={getClassname(overallAvg)}>{(overallAvg ? getAvg(overallAvg) : getAvg(0))}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="row mt-4 flex justify-content-end">
                            <div className="col-lg-6 col-md-12  ">
                                <table className="table-custom">
                                    <tbody>
                                    <tr>
                                        <th colSpan={10}
                                            className="text-align-center">    {i18n.t('reports.inspections.report.summary.overall-rating.legend.title', "OVERALL BUILDING PRESENTATION % RATING")}

                                        </th>
                                    </tr>
                                    <tr>
                                        <td className="text-align-center back-five"
                                            colSpan={2}>    {i18n.t('reports.inspections.report.summary.overall-rating.legend.5', "Excellent")}
                                        </td>
                                        <td className="text-align-center back-four"
                                            colSpan={2}>    {i18n.t('reports.inspections.report.summary.overall-rating.legend.4', "Very Good")}
                                        </td>
                                        <td className="text-align-center back-three"
                                            colSpan={2}>    {i18n.t('reports.inspections.report.summary.overall-rating.legend.3', "Good")}
                                        </td>
                                        <td className="text-align-center back-two"
                                            colSpan={2}>    {i18n.t('reports.inspections.report.summary.overall-rating.legend.2', "Fair")}
                                        </td>
                                        <td className="text-align-center back-one"
                                            colSpan={2}>    {i18n.t('reports.inspections.report.summary.overall-rating.legend.1', "Un/Sat")}
                                        </td>

                                    </tr>
                                    <tr>
                                        <td className="text-align-center back-five">100%</td>
                                        <td className="text-align-center back-five">90%</td>
                                        <td className="text-align-center back-four">89%</td>
                                        <td className="text-align-center back-four">81%</td>
                                        <td className="text-align-center back-three">80%</td>
                                        <td className="text-align-center back-three">71%</td>
                                        <td className="text-align-center back-two">70%</td>
                                        <td className="text-align-center back-two">61%</td>
                                        <td className="text-align-center back-one">60%</td>
                                        <td className="text-align-center back-one">25%</td>
                                    </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>


                        <div className="row">
                            <div className="col-sm-12 col-md-8  mt-5">
                                <div className="table-responsive ">

                                    <table className="table-custom">
                                        <tbody>
                                        <tr>
                                            <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.reviewed-by', "Reviewed By")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.signature', "Signature")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.date', "Date")}
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </>


                )

            }

        </div>
    )
}

export function AnsweredSpec(props) {
    const spec = props.spec;

    return (


        <tbody>
        <tr>
            <th>{spec.title}</th>
            <th className="text-align-center">{ratingArray.indexOf(spec.jobSpecType) != -1 ? 'Star Rating' : 'Completed'}</th>
            <th>{'Comments'}</th>
            <th className="text-align-center">Score (%)</th>
        </tr>
        {spec.specItems && spec.specItems.map(
            (specItem, i) => {
                return (<AnsweredSpecItem
                    specItem={specItem}
                    count={spec.specItems.length}
                    first={i == 0}
                    jobSpecType={spec.jobSpecType}
                    totalAverage={spec.averageRating}
                />)
            }
        )}
        </tbody>
    );
}

function getClassname(avg) {
    let className = "display-3 p-0 font-weight-bold text-align-center ";
    //console.log((avg);)
    className += avg >= 4.5 ? "text-five" :
        avg > 4 ? "text-four" :
            avg > 3.5 ? "text-three" :
                avg > 3 ? "text-two" :
                    "text-one";

    return className;

}

const ratingArray = ['INSPECTION', 'FEEDBACK']

export function AnsweredSpecItem(props) {

    const specItem = props.specItem;
    const count = props.count;
    const jobSpecType = props.jobSpecType;
    const avg = props.totalAverage ? props.totalAverage : 0;

    function getRating(questions) {
        if (ratingArray.indexOf(jobSpecType) < 0) {
            return questions && questions[0] && questions[0].answer ?
                questions[0].answer === 'true' ? 'Yes' : questions[0].answer

                : "Yes";
        }
        let rating = 0.0;
        let count = 0;
        if (questions) {
            questions.forEach((q) => {
                if (q.answerType.indexOf('RATING') != -1 && q.answer) {
                    rating += parseFloat(q.answer);
                    count++;
                }
            })
        }

        const num = count == 0 ? 0 : rating / count;
        return num.toFixed(1);
    }


    function getComment(questions) {
        let comment = "";
        if (questions) {
            questions.forEach((q) => {
                if (q.answerType === 'TEXT' && q.answer) {
                    comment += q.answer;
                }
            })
        }

        return comment;
    }

    function getAvg() {
        if (ratingArray.indexOf(jobSpecType) == -1) {
            return "";
        }
        return Math.ceil((avg / avgFactor) * 100) + "%";
    }

    return (
        <tr>
            <td>{specItem.title}</td>
            <td className="text-align-center">{getRating(specItem.questionnaires)}</td>
            <td>{getComment(specItem.questionnaires)}</td>
            {props.first && (
                <td rowSpan={count}>
                    <p className={getClassname(avg)}>{
                        getAvg()
                    }</p>
                </td>
            )}

        </tr>
    );
}


export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const selectedWork = props.selectedWork;
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);

    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }

    useEffect(() => {
        const client = allClientData[selectedWork.clientId];
        const user = allClientData[selectedWork.tenantUserId];
        const location = allLocationIds[selectedWork.locationId];
        const map = {};

        map['Client'] = getObj(client.user.firstName + " " + client.user.lastName, 3);
        map['Site Address'] = getObj(location.address, 3);
        map['Inspected By'] = getObj(user.user.firstName + " " + user.user.lastName, 3);
        setDmap(map);
    }, [])
    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>
                        <tr>
                            <td className="font-weight-bold p-0 pb-0  pr-2">Started Date</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.startTime).format('MMMM D, YYYY')}</td>

                            <td className="font-weight-bold p-0  pl-2">Started Time</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.startTime).format('HH:mm:SS')}</td>
                        </tr>

                        <tr>
                            <td className="font-weight-bold p-0 pb-0  pr-2">End Date</td>
                            <td className="pl-2 p-0 pb-0 pt-1 ">{moment(selectedWork.endTime).format('MMMM D, YYYY')}</td>

                            <td className="font-weight-bold p-0 pb-0  pl-2">End Time</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.endTime).format('HH:mm:SS')}</td>
                        </tr>
                        {
                            Object.keys(dMap).map((key) => {
                                return (<tr>
                                    <td className="font-weight-bold p-0 pb-0  pr-2">{key}</td>
                                    <td className="pl-2 p-0 pb-0 pt-1" colSpan={dMap[key].span}>{dMap[key].value} </td>
                                </tr>)
                            })
                        }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


