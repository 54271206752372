
import React, { useState, useEffect, setState, createRef } from "react";
import swal from "sweetalert";
import { getWorkSummary, workSummaryEdit } from "../../../../api";
import { date2str } from "../../../../utils/dateUtils"

export function WorkSummaryEdit(props) {

    const workid = props.workId;


    const [startDate, setStartDate] = useState("");
    const [startTime, setStartTime] = useState("");

    const [endDate, setEndDate] = useState("");
    const [endTime, setEndTime] = useState("");

    const [reason, setReason] = useState("");
    const [editHistory, setEditHistory] = useState(-1);
    useEffect(async () => {
        const res = await getWorkSummary(workid);
        if (res.success) {
            const data = res.data;
            setStartDate(date2str(new Date(data.startTime), 'yyyy-MM-dd'));
            setStartTime(date2str(new Date(data.startTime), 'hh:mm:ss'));
            setEndDate(date2str(new Date(data.endTime), 'yyyy-MM-dd'));
            setEndTime(date2str(new Date(data.endTime), 'hh:mm:ss'));
            setReason(data.editedReason);
            setEditHistory(data.editHistory);
        }
        else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }



    }, props.workId)


    async function _saveSummary(start, end, reason, workId) {
        //console.log((start, end, reason);)
        if (reason && reason.length > 0) {
            var st = new Date(start);
            var en = new Date(end);

            if (en.getTime() > st.getTime()) {
                const req = {
                    tenantId: props.companyId,
                    workId: workId,
                    startTime: new Date(start).toISOString(),
                    endTime: new Date(end).toISOString(),
                    reason: reason

                }
                const res = await workSummaryEdit(req, workId);
                if (res && res.success) {
                    swal({
                        icon: "success",
                        title: "Updated",
                        text: "Work summary updated"
                    }).then(() => {
                        props.setOpen(false);
                        props._loadReport();
                    })
                } else {
                    swal({
                        icon: "error",
                        title: "Error",
                        text: res.message
                    });
                }
            } else {
                swal({
                    icon: "error",
                    title: "Error",
                    text: "Invalid dates"
                });
            }



        } else {
            swal({
                icon: "error",
                title: "Error",
                text: "You should provide a reason"
            });
        }



    }
    return (
        <div className="card col-sm-12 grid-margin stretch-card">
            <div className="card-body text-align-left">

                <h4 className="card-title">Edit work</h4>
                <p className="card-description">Alter clock in and clock out times</p>

                <div className="forms-sample" >


                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="date-start">Start Date</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                id="date-start"
                                value={startDate}
                                onChange={(e) => { setStartDate(e.target.value) }}
                                placeholder="Date of Birth"
                            />
                        </div>

                        <div className="form-group col-md-3">
                            <label htmlFor="time-start">Start Time</label>
                            <input
                                type="time"
                                className="form-control form-control-sm"
                                id="time-start"
                                value={startTime}
                                onChange={(e) => { setStartTime(e.target.value) }}
                                placeholder="Date of Birth"
                            />
                        </div>

                        <div className="form-group col-md-3">
                            <label htmlFor="date-end">End Date</label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                id="date-end"
                                min={startDate}
                                value={endDate}
                                onChange={(e) => { setEndDate(e.target.value) }}
                                placeholder="Date of Birth"
                            />
                        </div>

                        <div className=" form-group col-md-3">
                            <label htmlFor="time-end">End Time</label>
                            <input
                                type="time"
                                className="form-control form-control-sm"
                                id="time-end"

                                value={endTime}
                                onChange={(e) => { setEndTime(e.target.value) }}
                                placeholder="Date of Birth"
                            />
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-3">
                            <label htmlFor="date-start">Current Version</label>
                            <input
                                disabled={true}
                                type="number"
                                className="form-control form-control-sm"
                                id="date-start"
                                value={editHistory}
                                placeholder="Date of Birth"
                            />
                        </div>

                        <div className="form-group col-md-9">
                            <label htmlFor="txtReason">Edit Reason</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                id="txtReason"
                                value={reason ? reason : ""}
                                onChange={(e) => setReason(e.target.value)}
                                placeholder="Reason"
                            />
                        </div>

                    </div>


                    <div className="row justify-flex-end">
                    <button className="btn btn-outline-secondary mr-2"
                            type="button"

                            onClick={
                                () => {

                                 props.setOpen(false);

                                }
                            }

                        >Cancel</button>
                       
                       
                        <button className="btn btn-primary"
                            type="button"

                            onClick={
                                () => {

                                    _saveSummary(

                                        startDate + " " + startTime,
                                        endDate + " " + endTime,
                                        reason,
                                        workid
                                    );

                                }
                            }

                        >Save</button>
                    </div>
                </div>


            </div>

        </div>

    );

}