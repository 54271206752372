import {AnimateDiv} from "../../../../components/animatedDiv";
import {FortressCalendar} from "../../shared/calendar";
import React from "react";
import i18n from "i18next";

export function CalendarView({
                                 title, addedCalEvents,
                                 events,
                                 handleCalendarClick,
                                 showAllEvents,
                                 setShowAllEvents, showSetAllButton
                             }) {
    return (
        <AnimateDiv>

            <div className="row">
                <div className="col p-2 flex justify-space-between">
                    <h1>{title}</h1>
                    {showSetAllButton && (
                        <div>
                        <btn
                            title=    {i18n.t('btn.view-all-events',"View all events")}

                            onClick={()=>setShowAllEvents(!showAllEvents)}
                            htmlFor="showALl" className={showAllEvents?"btn  btn-outline-primary":"btn btn-outline-secondary"}>Show all events</btn>

                        </div>

                    )}
                </div>



            </div>
            <FortressCalendar
                addedCalEvents={addedCalEvents}
                events={events}
                handleCalendarClick={handleCalendarClick}
            >
            </FortressCalendar>
        </AnimateDiv>
    );
}
