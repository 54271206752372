import React, { useEffect, useRef, useState } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
  Switch,
} from "react-router-dom";
import loadingImg from "./resources/img/loading.gif";

import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./state/store";

import { PrivateRoute, PublicRoute } from "./Routes";

import GlobalStyles from "./components/globalStyles";

import { AuthLayout, LogoutPage } from "./modules/authModule/authLayout";
import { Dashboard } from "./modules/pages/dashboard";
import { UserRegistration } from "./modules/pages/userPages/userRegistration";
import { AllUsers } from "./modules/pages/userPages/allUsers";
import { ManageGroups } from "./modules/pages/jobLocation/manageGroups";
import { RegisterJobLocation } from "./modules/pages/jobLocation/registerJobLocation";
import { ViewJobLocations } from "./modules/pages/jobLocation/viewJobLocations";
import { RegisterJobDetail } from "./modules/pages/jobPages/registerJobDetail";

import { JobPage } from "./modules/pages/jobPages/job";
import { JobEditPage } from "./modules/pages/jobPages/jobEdit";
import { CreateContract } from "./modules/pages/contractPages/createContract";
import { COLOR_PROFILE } from "./config";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import "./resources/css/custom.css";
import Loader from 'react-loader-spinner'
import Messenger from "./modules/pages/chat/Messenger";
import { AllContracts } from "./modules/pages/contractPages/allContracts";
import { AllGroups } from "./modules/pages/groups/allGroups";
import { GroupRegistration } from "./modules/pages/groups/groupRegistration";
import { Inspections } from "./modules/pages/reports/inspections";
import { KPI } from "./modules/pages/reports/KPI";
import { SitePayroll } from "./modules/pages/reports/payrollSite";
import { UserPayroll } from "./modules/pages/reports/payrollUser";
import { InspectionTrend } from "./modules/pages/reports/inspectionTrend";
import { HeatMap } from "./modules/pages/reports/heatMap";
import { ForgotPassword } from "./modules/authModule/forgotPassword";
import { notificationActions } from "./state/notifications"
import { DetailedWorkReport } from "./modules/pages/reports/detailedWorkReport";
import { getNotifications } from "./api";
import { JobNotification } from "./modules/pages/jobPages/notification";
import {JobCalendar} from "./modules/pages/jobPages/jobCalendar";

let registered = false;
function Layout() {
  const auth = useSelector((state) => state.auth?.profile?.userRole?.roleLevel);
  const userState = useSelector((state) => state.auth.profile);
  const dispatch = useDispatch();

  const [colors, setColors] = useState({
    colorMain: '#f11946',
    colorBackground: '#001737'
  });
  useEffect(() => {
    const fromSt = JSON.parse(sessionStorage.getItem(COLOR_PROFILE));
    if (fromSt) {
      setColors({ ...fromSt });
    }


//console.log(("First Time Ever");)


  }, []);



  // 1 reg/adduser and everything
  // 2 reg client add user add job add locations and cotracts
  // 3 ""
  // 4 view my profile/ view my jobs/ view my conversations/upload details to my jobs
  // 5 ""
  // 6 access my conversations

  return (
    <div>

      <main className="main">
        <div id="main-loader" className="main-loader">
          <Loader

            type="MutatingDots"
            color={colors.colorMain}
            secondaryColor={colors.colorBackground}
            height={100}
            width={100}
            radius={15}
            timeout={0} //3 secs

          />
        </div>

        <PrivateRoute component={Dashboard} path="/" exact strict />
        {auth === 1 || auth === 2 || auth === 3 ? (
          <>
            <PrivateRoute component={AllUsers} path="/users" exact strict />
            <PrivateRoute
              component={UserRegistration}
              path="/users/add"
              exact
              strict
            />
            <PrivateRoute
              component={UserRegistration}
              path="/users/edit"
              exact
              strict
            />


          
            <PrivateRoute
              component={ManageGroups}
              path="/groups"
              exact
              strict
            />

            <PrivateRoute
              component={RegisterJobLocation}
              path="/jobs/addlocation"
              exact
              strict
            />
            <PrivateRoute
              component={RegisterJobLocation}
              path="/jobs/addlocation/edit"
              exact
              strict
            />
            <PrivateRoute
              component={ViewJobLocations}
              path="/jobs/viewlocation/all"
              exact
              strict
            />
            <PrivateRoute
              component={RegisterJobDetail}
              path="/jobs/jobdetail/add"
              exact
              strict
              mode={"add"}
            />
            <PrivateRoute
              component={RegisterJobDetail}
              path="/jobs/jobdetail/edit"
              exact
              strict
              mode={"edit"}
            />
            <PrivateRoute
              component={JobPage}
              path="/job/add"
              exact
              strict

            />

            <PrivateRoute
              component={JobEditPage}
              path="/job/edit"
              exact
              strict

            />
             <PrivateRoute
              component={JobNotification}
              path="/job/notifications"
              exact
              strict

            />
            <PrivateRoute
                component={JobCalendar}
                path="/job/calendar"
                exact
                strict

            />
            <PrivateRoute
              component={CreateContract}
              path="/contracts/add"
              exact
              strict
            />

            <PrivateRoute
              component={CreateContract}
              path="/contracts/edit"
              exact
              strict

            />

            <PrivateRoute
              component={AllContracts}
              path="/contracts/all"
              exact
              strict

            />

            <PrivateRoute
              component={Messenger}
              path="/chat"
              exact
              strict

            />
            <PrivateRoute
              component={Inspections}
              path="/report/inspection"
              exact
              strict

            />
            <PrivateRoute
              component={KPI}
              path="/report/kpi"
              exact
              strict

            />
            <PrivateRoute
              component={SitePayroll}
              path="/report/payroll/site"
              exact
              strict

            />
            <PrivateRoute
              component={UserPayroll}
              path="/report/payroll/user"
              exact
              strict

            />
            <PrivateRoute
              component={KPI}
              path="/report/heatmap"
              exact
              strict

            />
            <PrivateRoute
              component={KPI}
              path="/report/track"
              exact
              strict

            />
            <PrivateRoute
              component={InspectionTrend}
              path="/report/trend/inspection"
              exact
              strict

            />
            <PrivateRoute
              component={DetailedWorkReport}
              path="/report/detailed"
              exact
              strict

            />
            <PrivateRoute
              component={HeatMap}
              path="/report/heat-map"
              exact
              strict

            />

          </>
        ) : null}
      </main>
    </div>
  );
}

function App() {
  const colors = sessionStorage.getItem(COLOR_PROFILE);

  return (
    <Provider store={store}>
      <Router>
        <PersistGate persistor={persistor}>
          <div className="App">
            <div id="auth-loader" className="main-loader" style={{ display: "none" }}>
              <img src={loadingImg} width="50px" />
            </div>
            {colors && <GlobalStyles />}
            <Switch>
              <PublicRoute
                restricted={true}
                component={LogoutPage}
                path="/logout"
                exact
              />
              <PublicRoute
                restricted={true}
                component={AuthLayout}
                path="/signin"
                exact
                strict
              />
              <PublicRoute
                restricted={true}
                component={AuthLayout}
                path="/password-reset"
                exact
              />
              <PrivateRoute component={Layout} path="/" />
            </Switch>
          </div>
        </PersistGate>
      </Router>
    </Provider>
  );
}

export default App;
