import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
};

const groupsSlice = createSlice({
  name: "groups",
  initialState: initState,
  reducers: {
    setGroups(state, { action, payload }) {
      for (var group of payload) {
        const { groups, ...rest } = group;
        if (!(group.id in state.byId)) {
          state.byId[group.id] = rest;
          state.allIds.push(group.id);
        }
      }
    },
    clearGroups(state, { action }) {
      state.byId = {};
      state.allIds = [];
    }
  },
});

export const groupActions = groupsSlice.actions;
export const groupReducer = groupsSlice.reducer;

export function setGroups(data) {
  return (dispatch, getState) => {
    try {
      dispatch(groupActions.setGroups(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting contracts"));
    }
  };
}
export function clearGroups() {
  return (dispatch, getState) => {
    try {
      dispatch(groupActions.clearGroups());
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting users"));
    }
  };
}

// export function createContract(data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.createContract(data);
//       dispatch(contractActions.setContractUpdate(response.data));
//       dispatch(showToastSuccess("Contract created", "Created succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function updateContract(contractId, data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.updateContract(contractId, data);
//       dispatch(contractActions.setContractUpdate(response.data));
//       dispatch(showToastSuccess("Contract updated", "Updated succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function deleteContract(contractId) {
//   return async (dispatch, getState) => {
//     try {
//       await api.deleteContract(contractId);
//       dispatch(contractActions.removeContract(contractId));
//       dispatch(showToastSuccess("Contract deleted", "Deleted succesfully!"));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error deleting contract"));
//     }
//   };
// }
