export function formatDate(d)
{
 var date = new Date(d)
 var dd = date.getDate(); 
 var mm = date.getMonth()+1;
 var yyyy = date.getFullYear(); 
 if(dd<10){dd='0'+dd} 
 if(mm<10){mm='0'+mm};
 return d = yyyy+'-'+mm+'-'+dd
}

export function date2str(x, y) {
    var z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds()
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? "0" : "") + z[v.slice(-1)]).slice(-2)
    });

    return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length)
    });
}