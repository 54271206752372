import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import {getJobs, notificationSubscribe} from "../../../api";
import {PageLayout} from "../../../components/pageLayout";
import {NotificationWidget} from "./shared/notificationWidget";
import i18n from "i18next";


export function JobNotification(props) {

    const companyState = useSelector((state) => state.company.profile);
    const allUserData = useSelector((state) => state.users.byId);
    const allUserIds = useSelector((state) => state.users.allIds);
    const [availableEmployees, setAvailableEmployees] = useState([]);
    const [columns, setColumns] = useState([]);
    const [rows, setRows] = useState([]);
    const [selectedJob, setSelectedJob] = useState({});
    const [jobs, setJobs] = useState([]);
    const [n_gateways, setN_Gateways] = useState({});

    const [n_subs, setN_Subs] = useState([]);

    const [gridOpt, setGridOpt] = useState({});

    const [open, setOpen] = useState(false);


    var initialized = false;
    useEffect(async () => {
        loadJobs();

    }, []);


    async function loadJobs(clientId, jobLocationId, jobDetailId) {
        const res = await getJobs(companyState.id, clientId, jobLocationId, jobDetailId);
        if (res.success) {
            setJobs(res.data);
            readJobFromURL(res.data);
        } else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }

    function readJobFromURL(jobs) {
        const id = new URLSearchParams(props.location.search).get("jobId");
        if (id) {
            const job = getJobFromIdInJobs(id, jobs);
            if (job) {
                setSelectedJob({...job})

                return true;
            }
        }
        return false;
    }

    function getJobFromId(id) {
        return getJobFromIdInJobs(id, jobs);
    }

    function getJobFromIdInJobs(id, jobs) {
        for (let job of jobs) {
            if (job.id == id) {
                return job
                break;
            }
        }
    }

    function reset() {
        setSelectedJob({});
    }

    const onJobChange = async (e) => {
        try {
            const id = parseInt(e.target.value);

            const job = getJobFromId(id);
            if (job) {
                setSelectedJob({...job})

            } else {
                reset();
            }
        } catch (err) {
            //console.log((err);)
            swal("Error Getting Contract Data!");
        }
    }


    const [saveData, setSaveData] = useState({});

    async function saveAllData() {
        if (saveData) {
            const res = await notificationSubscribe(saveData);
            swal({
                title: res && res.success ? 'Success' : 'Error',
                text: res && res.message ? res.message : "Something went wrong",
                icon: res && res.success ? 'success' : 'error',
                buttons: "Close"
            });
        } else {
            swal({
                title: 'No changes',
                text: 'Please make some changes',
                buttons: "Close"
            });
        }

    }

    function getNotiKVMap() {
        return selectedJob ? {
            "CLIENT_MESSAGE_TO_COMMUNICATION_BOOK": {
                table: "CONVERSATION",
                value: selectedJob.communicationConversationId
            },
            "MESSAGE_TO_COMMUNICATION_BOOK": {
                table: "CONVERSATION",
                value: selectedJob.communicationConversationId
            },
            "MESSAGE_TO_JOB_INTERNAL_CHAT": {
                table: "CONVERSATION",
                value: selectedJob.updatesConversationId
            },
            "MESSAGE_TO_COMPANY_INTERNAL_CHAT": {
                table: "CONVERSATION",
                value: selectedJob.tenantId
            },
            "ANY": {
                table: "JOB",
                value: selectedJob.id
            },

        } : {
            "ANY": {
                table: "JOB",
                value: selectedJob.id
            }
        }
    }

    return (
        <PageLayout>
            <div className="content-wrapper ">
                <div className="col-12 grid-margin stretch-card col-lg-12">
                    <div className="card">
                        <div className="card-body">

                            <h4 className="card-title">    {i18n.t('job.notifications.title',"Notifications")}
                            </h4>
                            <p className="card-description">    {i18n.t('job.notifications.description',"Setup notifications for jobs")}
                            </p>


                            <div className="row">
                                <div className="col-sm-12">

                                    <div className="row">
                                        <div className="col-sm-12 form-group">
                                            <label htmlFor="jobdetail_select"><h6>    {i18n.t('job.notifications.form.job',"Select a job")}
                                            </h6></label>
                                            <select
                                                className="form-control form-control-sm"
                                                id="jobdetail_select"
                                                onChange={onJobChange}
                                                value={selectedJob ? selectedJob.id : -1}
                                                required
                                            >
                                                <option value="-1">    {i18n.t('job.notifications.form.job.select',"Select job...")}
                                                </option>
                                                {jobs &&
                                                jobs.map((job) => {
                                                    return (
                                                        <option value={job.id} key={job.id}>
                                                            {job.id + ". "
                                                            +i18n.t('forms.job',"Job")+
                                                            " for " + job.jobLocation.name + (job.enabled ? "" : " (Deactivated)")}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            {selectedJob && selectedJob.id&& (
                                                <NotificationWidget
                                                    saveAll={setSaveData}
                                                    enableAddUsers={true}
                                                    mappedId={selectedJob.id}
                                                    notificationCategory={'JOB'}
                                                    notiKVMap={getNotiKVMap()}
                                                >

                                                </NotificationWidget>

                                            )}


                                        </div>

                                    </div>
                                    {selectedJob && selectedJob.id && (<div className="row pt-2 float-right">
                                        <div className="col">

                                            <button className="btn btn-primary" type="button" onClick={(e) => {
                                                saveAllData()
                                            }}>     {i18n.t('btn.save',"Save")}

                                            </button>

                                        </div>

                                    </div>)}


                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </PageLayout>

    );


}
