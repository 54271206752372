import {loadLanguageData} from "../api";
import i18n from "i18next";

const languageKey = "language";
const preferredLanguageKey = "preferredLanguage";

export const defaultLangNamespace = "ns1";


export async function loadLanguage(tenant, prefLang) {
    const tenantId=tenant.id;
    prefLang = getPrefLanguage(prefLang);
    const language = JSON.parse(localStorage.getItem(languageKey));
    if (language && language[tenantId] && language[tenantId]['language'] && language[tenantId]['language'] == prefLang) {
        setLanguage(prefLang,language[tenantId]);
    }else{
        setLanguage(prefLang,await getLanguageFromApi(tenantId, prefLang));
    }
}

function setLanguage(prefLang, lang) {
    if(lang && lang.items){

        i18n.addResources(prefLang.toLowerCase(), defaultLangNamespace, lang.items);
    }
}

function getPrefLanguage(prefLang) {
    if (prefLang) {
        return prefLang;
    }
    const localStroagePrefLang = localStorage.getItem(preferredLanguageKey);
    if (localStroagePrefLang) {
        return localStroagePrefLang;
    }
    return 'EN';
}

async function getLanguageFromApi(tenant, prefLang) {
    const res = await loadLanguageData(tenant, prefLang);
    if (res && res.data) {
        setLanguageToLocalStorage(tenant, res.data);
        return res.data;
    }
    return {};
}

export function setLanguageToLocalStorage(tenant, language) {
    if (tenant && language) {
        let storeItem = {};
        storeItem[tenant] = language;
        localStorage.setItem(languageKey, JSON.stringify(storeItem));
    }
}

export function clearLanguagesOnLogout() {
    localStorage.delete(languageKey);
}

