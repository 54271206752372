import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState: initState,
  reducers: {
    setNotifications(state, { action, payload }) {
      // //console.log((payload);)
      for (var notification of payload) {
        const { notifications, ...rest } = notification;
        if (!(notification.id in state.byId)) {
          state.byId[notification.id] = rest;
          state.allIds.push(notification.id);
        }
      }
    },
  
  },
});

export const notificationActions = notificationSlice.actions;
export const notificationReducer = notificationSlice.reducer;

export function setNotifications(data) {
  return (dispatch, getState) => {
    try {
      dispatch(notificationActions.setNotifications(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting locations"));
    }
  };
}
