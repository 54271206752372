import React, {useCallback, useEffect, useRef, useState} from "react";
import {PageLayout} from "../../components/pageLayout";
import {useDispatch, useSelector} from "react-redux";
import {
    getDashboardCountSummary,
    getJobLocations,
    getJobTrend,
    getSitesPayroll,
    getTenantUserSummary,
    getUsersPayroll,
    getUsersTrend,
    getWorkTrend
} from '../../api'
import * as apiActions from "../../state/locations";
import {Bar, Doughnut, HorizontalBar, Line, Pie, Radar} from 'react-chartjs-2';
import {usePagination, useSortBy, useTable} from 'react-table'
import CountUp from 'react-countup';
import userDefaultImg from "../../resources/img/user/user-img-default.png";
import moment from 'moment';
import * as config from "../../config";
import {GoogleMap, Marker, useLoadScript,} from "@react-google-maps/api";
import i18n from "i18next";
// f
const frequencies = {
    "Day": "DAILY",
    "Week": "WEEKLY",
    "Month": "MONTHLY",
    "Year": "ANNUALLY"
}

function random_rgba(opacity) {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + opacity + ')';
}

function getBGColors(count, isSingle) {
    let colors = [
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(188, 185, 20,0.2)',
        'rgba(35, 8, 201,0.2)',
        'rgba(95, 125, 83,0.2)',
        'rgba(15, 75, 173,0.2)',
        'rgba(102, 43, 52,0.2)',
        'rgba(24, 182, 41,0.2)',
        'rgba(31, 206, 241,0.2)',
        'rgba(118, 94, 180,0.2)',
        'rgba(198, 251, 254,0.2)',
        'rgba(241, 206, 118,0.2)',

    ];
    if (isSingle) {
        return colors[count - 1];
    }
    return colors.slice(0, count - 1);
}

function getBordersColors(count, isSingle) {
    let colors = [
        'rgba(54, 162, 235, 1)',
        'rgba(255, 99, 132, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
        'rgba(255, 159, 64, 1)',
        'rgba(188, 185, 20,1)',
        'rgba(35, 8, 201,1)',
        'rgba(95, 125, 83,1)',
        'rgba(15, 75, 173,1)',
        'rgba(102, 43, 52,1)',
        'rgba(24, 182, 41,1)',
        'rgba(31, 206, 241,1)',
        'rgba(118, 94, 180,1)',
        'rgba(198, 251, 254,1)',
        'rgba(241, 206, 118,1)',
    ];
    if (isSingle) {
        return colors[count - 1];
    }
    return colors.slice(0, count - 1);
}

const trendMapKey = "trendMap";
const insightKey = 'Insight';

export function Dashboard() {
    const allData = useSelector((state) => state.locations.byId);
    const companyState = useSelector((state) => state.company.profile);
    const [countMap, setCountMap] = useState({});
    const [employeeTotal, setEmployeeTotal] = useState({});
    const [jobTrend, seJobTrend] = useState({});
    const [usersTrend, setUsersTrend] = useState({});
    const [workTrend, setWorkTrend] = useState({});
    const [sitesPayroll, setSitesPayroll] = useState({});
    const [usersPayroll, setUsersPayroll] = useState({});
    const [tenantUsersSummary, setTenantUsersSummary] = useState([]);
    const [tenantUsersSummaryById, setTenantUsersSummaryById] = useState({});
    const [locationsById, setLocationsById] = useState({});
    const [markers, setMarkers] = useState([]);
    const dispatch = useDispatch();


    useEffect(async () => {
        _getJobLocations();

        _getTenantUserSummary();
        _getDashboardCountSummary();
        _getWorkTrend(frequencies['Week']);
        _getJobTrend(frequencies['Week']);
        _getUsersTrend(frequencies['Week']);


    }, [])


    async function _getSitesPayroll(byId) {

        const res = await getSitesPayroll(companyState.id);
        if (res && res.data) {
            const map = {};
            const data = res.data;
            Object.keys(data).forEach(
                (k) => {
                    const id = parseInt(k);
                    if (id) {
                        map[byId[id]?.name] = Math.ceil(data[id] / (1000 * 60 * 60));
                    }

                }
            )
            setSitesPayroll({trendMap: map});

        }
    }

    async function _getUsersPayroll(byId) {

        const res = await getUsersPayroll(companyState.id);
        if (res && res.data) {
            const map = {};
            const data = res.data;
            Object.keys(data).forEach(
                (k) => {
                    const id = parseInt(k);
                    if (id) {
                        const username = byId[id] ? byId[id].firstName + " " : "Deleted user";
                        map[username + " " + id] = Math.ceil(data[id] / (1000 * 60 * 60));
                    }
                }
            )
            setUsersPayroll({trendMap: map});

        }
    }

    async function _getJobLocations() {
        if (!allData || !allData.length) {
            const res = await getJobLocations(companyState.id, null, null, 0, 200);
            if (res) {
                dispatch(apiActions.setLocations(res.data));
                let markers = [];
                res.data.forEach((loc) => {
                    markers.push({
                        position: {
                            lat: loc.lat,
                            lng: loc.lang
                        }
                    })
                });
                setMarkers(markers);
                const byid = {};
                res.data.forEach(
                    (u) => {
                        byid[u.id] = u;
                    }
                )
                setLocationsById({...byid});
                _getSitesPayroll(byid);
            }
        }
    }

    async function _getDashboardCountSummary() {
        const res = await getDashboardCountSummary(companyState.id);
        if (res && res.success) {
            setCountMap(res.data.countMap);
            setEmployeeTotal(res.data.employeeTotal);
        }
    }

    async function _getJobTrend(frequency) {
        const res = await getJobTrend(companyState.id, frequency);
        if (res && res.success) {
            seJobTrend(res.data);
        }
    }

    async function _getUsersTrend(frequency) {
        const res = await getUsersTrend(companyState.id, frequency, null);
        if (res && res.success) {
            setUsersTrend(res.data);
        }
    }

    async function _getWorkTrend(frequency) {
        const res = await getWorkTrend(companyState.id, frequency, null, null, null, null);
        if (res && res.success) {
            setWorkTrend(res.data);
        }
    }

    async function _getTenantUserSummary() {
        const res = await getTenantUserSummary(companyState.id, null, null);
        if (res && res.success) {
            setTenantUsersSummary(res.data);
            const byid = {};
            res.data.forEach(
                (u) => {
                    byid[u.tenantUserId] = u;
                }
            )
            setTenantUsersSummaryById({...byid});
            _getUsersPayroll(byid);
        }

    }

    const data = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: '# of Votes',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: getBGColors(6),
                borderColor: getBordersColors(6),
                borderWidth: 1,
            },
        ],
    }

    function getSitesPayrollWithKeys(sites) {
        const map = sites ? sitesPayroll : usersPayroll;
        const modifiedMap = {};
        if (map) {
            map.keys.forEach(
                (key) => {

                }
            )
        }
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
    }


    const colsUserStatusTable = [
            {
                Header: '',
                accessor: 'user',
                image: true
            },
            {
                Header: i18n.t('table.users.header.name', "Name"),
                accessor: 'name',
            }, {
                Header: i18n.t('table.users.header.role', "Role"),
                accessor: 'role',
            },
            {
                Header: i18n.t('table.users.header.status', "Status"),
                accessor: 'status',
            },
            {
                Header: i18n.t('table.users.header.emergency', "Emergency"),
                accessor: 'emergency',
            }
        ]
    ;

    function getStatus(status, time) {
        if (!status || status === 'ONLINE') {
            return "Online";
        }
        if (!time) {
            return status;
        }
        return "Online " + moment(time).fromNow();
    }

    function getCount(key) {
        return countMap && countMap[key] ? countMap[key] : 0;
    }

    return (
        <PageLayout>
            <div className="content-wrapper">

                <div className="row">
                    <div className="col-md-2 grid-margin stretch-card">
                        <CountItem
                            icon={"mdi-account-circle"}
                            title={i18n.t('dashboard.top.clients', "Clients")}

                            count={getCount('CLIENTS_TOTAL')}
                        >
                        </CountItem>
                    </div>
                    <div className="col-md-2 grid-margin stretch-card">
                        <CountItem
                            icon={"mdi-account-multiple-outline"}
                            title={i18n.t('dashboard.top.employees', "Employees")}

                            count={getCount('EMPLOYERS_TOTAL')}
                        >
                        </CountItem>
                    </div>
                    <div className="col-md-2 grid-margin stretch-card">
                        <CountItem
                            icon={"mdi-map-marker"}
                            title={i18n.t('dashboard.top.sites', "Sites")}

                            count={getCount('JOB_SITES_TOTAL')}
                        >
                        </CountItem>
                    </div>
                    <div className="col-md-2 grid-margin stretch-card">
                        <CountItem
                            icon={"mdi-checkbox-multiple-blank-outline"}
                            title={i18n.t('dashboard.top.jobs', "Jobs")}

                            count={getCount('JOBS_TOTAL')}
                        >
                        </CountItem>
                    </div>
                    <div className="col-md-2 grid-margin stretch-card">
                        <CountItem
                            icon={"mdi-checkbox-multiple-marked"}
                            title={i18n.t('dashboard.top.works', "Works")}

                            count={getCount('WORKS_TOTAL')}
                        >
                        </CountItem>
                    </div>
                    <div className="col-md-2 grid-margin stretch-card">


                        <CountItem
                            icon={"mdi-database"}
                            title={i18n.t('dashboard.top.storage', "Storage")}

                            count={Math.ceil(getCount('STORAGE_USED') / (1024 * 1024))}
                            second={"/ " + (5 * 1024) + " MB"}
                        >
                        </CountItem>
                    </div>
                </div>


                <div className="row">
                    <div className="col-12 col-xl-6  stretch-card">

                        <TrendGraph
                            key="workTrend"
                            trend={workTrend}
                            title={i18n.t('dashboard.task-trend.title', "Completed Tasks Trend")}

                            label={"# of tasks"}
                            updateTrend={_getWorkTrend}
                            chartType={"Bar"}
                            selectedFreq={'Week'}
                            bgColors={getBGColors(1, true)}
                            borderColors={getBGColors(1, true)}

                        >
                        </TrendGraph>
                    </div>
                    <div className="col-12 col-xl-6 stretch-card">

                        <TrendGraph
                            key="jobTrend"
                            trend={jobTrend}
                            title={i18n.t('dashboard.jobs-trend.title', "Jobs Trend")}

                            label={"# of jobs"}
                            updateTrend={_getJobTrend}
                            chartType={"Line"}
                            selectedFreq={'Week'}
                            bgColors={getBGColors(4, true)}
                            borderColors={getBordersColors(4, true)}


                        >

                        </TrendGraph>
                    </div>

                </div>


                <div className="row">
                    <div className="col-xl-6">
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">    {i18n.t('table.users.title', "Users")}
                                        </h4>
                                        <div className="table-responsive">

                                            <Table
                                                columns={
                                                    colsUserStatusTable
                                                }

                                                data={
                                                    tenantUsersSummary.map((user) => {
                                                            return {
                                                                user: user.imageURL ? user.imageURL : userDefaultImg,
                                                                name: user.firstName + " " + user.lastName,
                                                                role: user.role,
                                                                status: getStatus(user.userStatus, user.statusUpdatedTime),
                                                                emergency: user.availableForEmergency ? "Available" : "N/A",
                                                            }
                                                        }
                                                    )
                                                }

                                            >

                                            </Table>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-12  stretch-card ">
                                <TrendGraph
                                    key="userSpider"
                                    trend={{
                                        trendMap: employeeTotal
                                    }}
                                    title={i18n.t('dashboard.users-spider.title', "Users Spider")}
                                    subtitle={i18n.t('dashboard.users-spider.description', "Users Spider")}

                                    label={"# of users"}
                                    disableFrequencyButtons={true}
                                    updateTrend={_getUsersTrend}
                                    chartType={"Radar"}
                                    selectedFreq={'Week'}
                                    bgColors={getBGColors(1, true)}
                                    borderColors={getBordersColors(1, true)}

                                >

                                </TrendGraph>

                            </div>

                            <div className="col-lg-12  stretch-card">
                                <TrendGraph
                                    key="usersPayroll"
                                    trend={usersPayroll}
                                    title={i18n.t('dashboard.users-time-log.title', "Users Time Log")}

                                    subtitle={i18n.t('dashboard.users-time-log.description', "Users time log in hours")}
                                    label={"# of mins"}
                                    disableFrequencyButtons={true}
                                    chartType={"Pie"}
                                    bgColors={getBGColors(10, false)}
                                    borderColors={getBGColors(10, false)}

                                >

                                </TrendGraph>
                            </div>

                        </div>

                    </div>


                    <div className="col-xl-6">
                        <div className="row">


                            <div className="col-lg-12  stretch-card">
                                <TrendGraph
                                    key="userTrend"
                                    trend={usersTrend}
                                    title={i18n.t('dashboard.users-trend.title', "Users Trend")}

                                    label={"# of users"}
                                    updateTrend={_getUsersTrend}
                                    chartType={"HorizontalBar"}
                                    selectedFreq={'Week'}
                                    bgColors={getBGColors(5, true)}
                                    borderColors={getBGColors(5, true)}

                                >

                                </TrendGraph>
                            </div>

                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">    {i18n.t('dashboard.job-sites.title', "Job Sites")}
                                        </h4>

                                        <MapComponent
                                            key="mapC"
                                            markers={markers}
                                        >

                                        </MapComponent>
                                    </div>
                                </div>
                            </div>


                            <div className="col-lg-12  stretch-card">
                                <TrendGraph
                                    key="sitesPayroll"
                                    trend={sitesPayroll}
                                    title={i18n.t('dashboard.site-time-log.title', "Site Time Log")}

                                    subtitle={i18n.t('dashboard.site-time-log.description', "Site time log in hours")}

                                    label={"# of mins"}
                                    disableFrequencyButtons={true}
                                    chartType={"Doughnut"}
                                    bgColors={getBGColors(10, false)}
                                    borderColors={getBGColors(10, false)}

                                >

                                </TrendGraph>
                            </div>
                        </div>
                    </div>


                </div>


            </div>
        </PageLayout>
    );
}

export function CountItem(props) {

    return (
        <div className="card  d-flex align-items-left">


            <div className="card-body">
                <div className="card-title row flex align-center">
                    <i className={"mdi " + props.icon + " text-black icon-md"}></i>
                    <h5 className="text-secondary font-weight-bold ml-3 mt-2">
                        {props.title}
                    </h5>
                </div>
                <div className="d-flex flex-row col align-items-center flex-wrap justify-content-md-center  py-1">
                    <div className=" ml-md-0  row flex align-bottom">
                        <CountUp
                            className="text-black  display-1"
                            start={0}
                            end={props.count}
                            duration={3}
                        >

                        </CountUp>
                        {props.second && (
                            <p className="mb-2 ml-2">
                                {props.second}
                            </p>
                        )}


                    </div>

                </div>
            </div>
        </div>
    );

}

export function TrendGraph(props) {

    function emptyData() {
        var data = {
            labels: [],
            datasets: [
                {
                    label: props.label,
                    data: [],
                    fill: true,
                    backgroundColor: getBGColors(1),
                    borderColor: getBordersColors(1),
                },
            ],
        }
        return data;
    }

    function getData(single) {
        let num = 5;
        if (single) {
            num = Math.floor(Math.random() * 5);
        }
        if (!props.trend || !props.trend[trendMapKey]) {
            return emptyData();
        }
        var data = {
            labels: Object.keys(props.trend.trendMap),
            datasets: [
                {
                    label: props.label,
                    data: Object.values(props.trend.trendMap),
                    fill: true,
                    backgroundColor: props.bgColors,
                    borderColor: props.borderColors,
                    borderWidth: 2,
                },
            ],
        }
        return data;
    }

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                    },
                },
            ],
        },
        legend: false
    }

    return (
        <div className="row w-100 flex-grow">
            <div className="col-md-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <p className="card-title">{props.title}</p>
                        <p className="text-muted">
                            {props.trend.data && props.trend.data[insightKey] ?
                                props.trend.data[insightKey] :
                                props.subtitle ? props.subtitle :
                                    props.title
                            }
                        </p>
                        <div className="row mb-3 justify-content-md-end flex">
                            {!props.disableFrequencyButtons && (
                                <div className="col-md-10">
                                    <ul
                                        className="nav nav-pills nav-pills-custom justify-content-md-end"
                                        id="pills-tab-custom"
                                        role="tablist"
                                    >
                                        {Object.keys(frequencies).map((freq) => {
                                            return (
                                                <li className="nav-item">
                                                    <a
                                                        className={"nav-link " + (freq == props.selectedFreq ? "active" : "")}
                                                        id="pills-home-tab-custom"
                                                        data-toggle="pill"
                                                        href="#pills-health"
                                                        role="tab"
                                                        aria-controls="pills-home"
                                                        aria-selected={freq == 'Week' ? "true" : "false"}
                                                        onClick={(e) => {
                                                            props.updateTrend(frequencies[freq])
                                                        }}
                                                    >
                                                        {freq}
                                                    </a>
                                                </li>
                                            )
                                        })}


                                    </ul>
                                </div>

                            )}

                        </div>
                        {
                            props.chartType === 'Line' ?
                                (
                                    <Line data={getData(true)}
                                          options={options}></Line>

                                ) :

                                props.chartType === 'HorizontalBar' ?
                                    (
                                        <HorizontalBar data={getData(false)}></HorizontalBar>
                                    ) :
                                    props.chartType === 'Radar' ?
                                        (
                                            <Radar data={getData(false)} options={{legend: false}}></Radar>
                                        ) :
                                        props.chartType === 'Pie' ?
                                            (
                                                <Pie data={getData(false)}></Pie>
                                            )
                                            :
                                            props.chartType === 'Doughnut' ?
                                                (
                                                    <Doughnut data={getData(false)}></Doughnut>
                                                ) :


                                                (
                                                    <Bar data={getData(false)} options={{legend: false}}></Bar>

                                                )


                        }
                    </div>
                </div>
            </div>

        </div>

    )
}


function Table({columns, data}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: {pageIndex, pageSize},
    } = useTable(
        {
            columns,
            data,
            initialState: {pageSize: 5}
        },
        useSortBy,
        usePagination
    )

    // We don't want to render all 2000 rows for this example, so cap
    // it at 20 for this use case

    return (
        < div className="table-responsive">
            <table className="table table-striped" {...getTableProps()}>
                <thead>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            // Add the sorting props to control sorting. For this example
                            // we can add them into the header props
                            <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                {column.render('Header')}
                                {/* Add a sort direction indicator */}
                                <span>
                    {column.isSorted
                        ? column.isSortedDesc
                            ? ' 🔽'
                            : ' 🔼'
                        : ''}
                  </span>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {page.map(
                    (row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.column.image ? (<img className="card-img-top  avatar-img"
                                                                       src={
                                                                           cell.value
                                                                       }
                                            >

                                            </img>) : cell.render('Cell')}


                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    }
                )}
                </tbody>
            </table>
            <div className="row justify-content-md-end flex">
                <div className="col-md-7">
                    <div className="pagination  justify-content-md-end d-flex">
                        <nav>
                            <ul className="pagination flex-wrap">
                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => gotoPage(0)}
                                            disabled={!canPreviousPage}>
                                        {'<<'}
                                    </button>
                                </li>
                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => previousPage()}
                                            disabled={!canPreviousPage}>
                                        {'<'}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => nextPage()} disabled={!canNextPage}>
                                        {'>'}
                                    </button>
                                </li>

                                <li className="page-item">
                                    <button className="btn btn-sm" onClick={() => gotoPage(pageCount - 1)}
                                            disabled={!canNextPage}>
                                        {'>>'}
                                    </button>
                                </li>


                            </ul>
                        </nav>

                    </div>


                </div>

                <div className="col-md-3">
          <span>
            Page{' '}
              <input
                  className=" form-control-sm col-md-5 "
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={e => {
                      const page = e.target.value ? Number(e.target.value) - 1 : 0
                      gotoPage(page)
                  }}
              />
            <strong>
              of {pageOptions.length}
            </strong>{' '}
          </span>

                </div>


            </div>


        </ div>
    )
}

const mapContainerStyle = {
    height: "430px",
    width: "100%",
};
const libraries = ["places"];
const options = {
    disableDefaultUI: true,
    zoomControl: true,
};
export const MapComponent = (props) => {


    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: config.GOOGLE_API_KEY,
        libraries,
    });

    const [center, setCenter] = useState({});
    const mapRef = useRef();

    useEffect(() => {
        if (props.markers && props.markers.length > 0) {
            setCenter({
                lat: props.markers[0].position.lat,
                lng: props.markers[0].position.lng
            });
        } else {
            setCenter({
                lat: 25.2744,
                lng: 133.7751
            });
        }

    }, []);


    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(16);
    }, []);


    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    return (
        <div>


            <GoogleMap
                id="map2"
                mapContainerStyle={mapContainerStyle}
                zoom={2}
                center={center && center}
                options={options}
                onLoad={onMapLoad}
            >
                {props.markers && props.markers.map(marker => (
                    <Marker
                        {...marker}
                        onClick={(p) => {
                            panTo(marker.position)
                        }
                        }
                    />
                ))}


            </GoogleMap>

        </div>
    );
};