export function filterJobLocationsForGroupsById(jobLocations, groups) {
    let filteredList = {};
    if (groups && jobLocations) {

        const gids = groups.map((g) => { return g.id });

        jobLocations.forEach((loc) => {
            if (!filteredList[loc.id] && loc.jobLocationGroups && loc.jobLocationGroups.length > 0) {

                const availableGids = loc.jobLocationGroups.map((g) => { return g.id });
                const found = availableGids.some(r => gids.indexOf(r) >= 0);
                if (found) {
                    filteredList[loc.id] = loc;
                }
            }

        });

    }
    return filteredList;
}