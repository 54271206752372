import {PageLayout} from "../../../components/pageLayout";
import {ReportFilter} from "./shared/filter";
import React, {createRef, useCallback, useEffect, useRef, useState} from "react";
import {downloadWorkDetailPdf, getPolyLineMapData, getWork, getWorks} from "../../../api";
import {useSelector} from "react-redux";
import swal from "sweetalert";
import fileImage from "../../../resources/img/other/file.png";
import moment from 'moment';
import ReactDOM from 'react-dom';
import {formatDate} from "../../../utils/dateUtils";
import {filterJobLocationsForGroupsById} from "../../../utils/objectFilter"
import {GoogleMap, Marker, Polyline, useLoadScript} from "@react-google-maps/api";
import * as config from "../../../config";
import Button from "@material-ui/core/Button";
import {Menu, MenuItem} from "@material-ui/core";
import i18n from "i18next";

const statuses = {
    "Completed": "COMPLETED",
    "Ongoing": "STARTED",
    "On hold": "ON_HOLD",
}
const specTypes = {
    "All": "ALL",
    "Inspections": "INSPECTION",
    "Tasks": "NORMAL",
    "Feedbacks": "FEEDBACK",

}
const specTypesNames = {
    "Inspections": i18n.t('forms.type.inspections', "Inspections"),
    "Feedbacks": i18n.t('forms.type.feedbacks', "Feedbacks"),
    "Tasks": i18n.t('forms.type.tasks', "Tasks"),
    "All": i18n.t('forms.type.all', "All")
}
const specTypesTitle = {
    "INSPECTION": "Inspection done",
    "FEEDBACK": "Feedback given",
    "NORMAL": "Task completed",
    "ALL": "Task completed"
}

export function DetailedWorkReport() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);
    const allLocationIds = useSelector((state) => state.locations.byId);
    const [works, setWorks] = useState([]);
    const [selectedWork, setSelectedWork] = useState({});
    const [selectedWorkId, setSelectedWorkId] = useState(-1);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [selectedSpec, setSelectedSpec] = useState("");
    const [selectedSite, setSelectedSite] = useState(-1);
    const allGroupData = useSelector((state) => state.groups.byId);

    const [groups, setGroups] = useState([]);
    const [LocationsById, setLocationsById] = useState({...allLocationIds});

    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    useEffect(() => {
        setSelectedStatus("COMPLETED");
        setSelectedSpec('All')
    }, [])

    async function onSiteChange(e) {
        const id = e.target.value;
        if (id && id != -1 && dates.start && dates.end && selectedStatus) {
            _loadWorks(id, dates.start, dates.end, selectedStatus);

        }

        setSelectedSite(e.target.value);

    }

    async function onDateRangeChange(st, en) {
        setDates({start: st, end: en});
        if (selectedSite != -1 && st && en && selectedStatus) {
            _loadWorks(selectedSite, st, en, selectedStatus);

        }


    }

    async function changeStatus(stat) {
        setSelectedStatus(statuses[stat]);
        if (selectedSite != -1 && dates.start && dates.end && statuses[stat]) {
            _loadWorks(selectedSite, dates.start, dates.end, statuses[stat]);

        }

    }

    const onWorkChange = (e) => {

        setSelectedWorkId(e.target.value);

    }

    async function _loadWork() {
        const spec = (!selectedSpec || selectedSpec === 'All') ? null : specTypes[selectedSpec];
        if (selectedWorkId && selectedWorkId != -1) {
            const id = selectedWorkId;

            const res = await getWork(id, spec);
            if (res.success) {
                const work = res.data;
                work['display'] = getDisplayName(work, specTypes[selectedSpec]);
                setSelectedWork({...work});
            } else {
                swal({
                    title: 'Error',
                    text: res.message,
                    icon: 'error'
                });
            }
        } else {
            swal(
                "Please select a work to generate report"
            );
        }

    }

    const getDisplayName = (work, spec) => {
        if (!spec) {
            spec = specTypes[selectedSpec];
        }
        spec = spec == 'ALL' ? null : spec;
        const user = allClientData[work.tenantUserId];
        return (spec ? specTypesTitle[spec] : "Task completed") + " by " + " (" + user.userRole.name + ") " + user.user.firstName + " " +
            user.user.lastName + " on "
            + (work.endTime ? moment(work.endTime).format('MMMM D, YYYY, HH:mm') : moment(work.startTime).format('MMMM D, YYYY, HH:mm'));


    }

    async function _loadWorks(site, startDate, endDate, status, spec) {
        //console.log((companyState.id, site, formatDate(startDate), formatDate(endDate), status);)
        if (!spec) {
            spec = specTypes[selectedSpec];
        }
        spec = spec == 'ALL' ? null : spec;
        const res = await getWorks(companyState.id, site, formatDate(startDate), formatDate(endDate), status, spec);
        if (res.success) {
            const works = res.data;
            if (works) {
                works.forEach((work) => {
                    work['display'] = getDisplayName(work, spec);
                })

            }
            setWorks([...works]);
        } else {
            swal({
                title: 'Error',
                text: res.message,
                icon: 'error'
            });
        }
    }

    async function changeSpecType(spec) {
        setSelectedSpec(spec);
        if (selectedSite != -1 && dates.start && dates.end && selectedStatus) {
            _loadWorks(selectedSite, dates.start, dates.end, selectedStatus, specTypes[spec]);

        }
    }

    function _setGroups(groups) {

        if (groups && groups.length > 0) {
            const filteredById = filterJobLocationsForGroupsById(Object.values(allLocationIds), groups);
            setLocationsById({...filteredById});

        } else {
            setLocationsById({...allLocationIds});

        }
        setGroups(groups);
    }

    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">    {i18n.t('reports.detailed.title',"Detailed Report")}
                                </h4>
                            <p className="card-description">{i18n.t('reports.detailed.description',"Generates detailed report")}</p>

                            <div className="row">
                                <div className="mt-2 mb-2 filter col-sm-12">
                                    <ReportFilter
                                        key="reportFilter1"
                                        onSiteChange={onSiteChange}
                                        onDateRangeChange={onDateRangeChange}
                                        selectedStatus={selectedStatus}
                                        statuses={statuses}
                                        start={dates.start}
                                        end={dates.end}
                                        jobStatus={true}
                                        locationFilter={true}
                                        allLocationIds={LocationsById}
                                        changeStatus={changeStatus}
                                        groupSelection={allGroupData && Object.keys(allGroupData).length > 0}
                                        groups={groups}
                                        setGroups={_setGroups}
                                        allGroups={Object.values(allGroupData)}

                                    >
                                    </ReportFilter>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="jobdetail_select"><h6> {i18n.t('forms.work', "Completed works")}
                                    </h6></label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="jobdetail_select"
                                        onChange={(e) => onWorkChange(e)}
                                        required

                                    >
                                        <option value="-1"> {i18n.t('forms.work.select', "Select work...")}
                                        </option>
                                        {works &&
                                        works.map((work) => {
                                            return (
                                                <option value={work.id} key={work.id}>
                                                    {work.id + ". " + work.display}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>

                                <div className="col-md-6 col-lg-3">
                                    <label htmlFor="date-filter">  {i18n.t('forms.type', "Type")}
                                    </label>

                                    <ul
                                        className="nav nav-pills nav-pills-custom justify-content-md-start "
                                        id="pills-tab-custom"
                                        role="tablist"
                                    >
                                        {Object.keys(specTypes).map((spec) => {
                                            return (
                                                <li className="nav-item ">
                                                    <a
                                                        className={"nav-link " + (spec == selectedSpec ? "active" : "")}
                                                        id="pills-home-tab-custom"
                                                        data-toggle="pill"
                                                        href="#pills-health"
                                                        role="tab"
                                                        aria-controls="pills-home"
                                                        aria-selected={spec === 'Inspections' ? "true" : "false"}
                                                        onClick={(e) => {
                                                            changeSpecType(spec)
                                                        }}
                                                    >
                                                        {specTypesNames[spec] ? specTypesNames[spec] : spec}
                                                    </a>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>

                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                            onClick={(e) => _loadWork()}
                                            className="btn btn-primary btn-lg">  {i18n.t('btn.generate', "Generate")}

                                    </button>

                                </div>
                            </div>


                        </div>
                    </div>

                </div>
                {
                    selectedWork && selectedWork.tenantId && (
                        <div className="row">
                            <div className="col-sm-12">
                                <InspectionReport
                                    key="inspectinReport"
                                    selectedWork={selectedWork}
                                    selectedSpec={selectedSpec}
                                    popup={false}
                                >
                                </InspectionReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}

function getAvg(avg) {

    return Math.ceil((avg / 5.0) * 100) + "%";
}

function getOverallRating(inspections) {
    let count = 0;
    let average = 0.0;
    inspections.forEach(
        (spec) => {
            if (spec.averageRating) {
                average += spec.averageRating;
                count++;
            }
        }
    )
    return count == 0 ? 0 : (average / count);
}


export function InspectionReport(props) {
    const ref = createRef();
    const selectedWork = props.selectedWork;
    const selectedSpec = props.selectedSpec;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    function getFileName() {
        return "selectedSpec.pdf";
    }

    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };


    async function downloadPdf(reportType) {
        if (reportType == null) {
            reportType = 'CSV';
        }
        let error = "";
        //console.log((selectedWork);)
        const spec = (!selectedSpec || selectedSpec === 'All') ? null : specTypes[selectedSpec];
        if (error === '') {
            const res = await downloadWorkDetailPdf(selectedWork.workId, spec, reportType);

            if (res && res.data) {

                let binaryString = window.atob(res.data);
                let binaryLen = binaryString.length;

                let bytes = new Uint8Array(binaryLen);

                for (let i = 0; i < binaryLen; i++) {
                    let ascii = binaryString.charCodeAt(i);
                    bytes[i] = ascii;
                }

                let blob = new Blob([bytes], {type: reportType == 'PDF' ? "application/pdf" : 'text/csv'});

                let link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                link.download = selectedWork.display + "." + reportType.toLowerCase();

                link.click();

            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">  "reports.site-payroll.report.title": "Report",
                            </h4>
                            {!props.popup && (
                                <>
                                    <Button aria-controls="simple-menu" className="btn btn-primary" variant="contained"
                                            color="success"
                                            size="large" aria-haspopup="true" onClick={handleClick}>
                                        {i18n.t('btn.download',"Download")}

                                    </Button>
                                    <Menu
                                        id="simple-menu"


                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => downloadPdf('PDF')}>As PDF</MenuItem>
                                        <MenuItem onClick={() => downloadPdf('CSV')}>As CSV</MenuItem>
                                    </Menu>
                                </>

                            )}

                        </div>

                        <Report
                            popup={props.popup}
                            selectedWork={selectedWork}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

export function Report(props) {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );


    return (
        <div if="pdf">

            <ul className="nav nav-pills nav-pills-primary">
                <li className="nav-item">
                    <a className="nav-link active" id="pills-task-tab" data-toggle="pill" href="#pills-task"
                       role="tab" aria-controls="pills-task" aria-selected="true">    {i18n.t('reports.detailed.task-detail',"Task detail")}
                    </a>
                </li>

                <li className="nav-item">
                    <a className="nav-link" id="pills-location-tab" data-toggle="pill" href="#pills-location"
                       role="tab" aria-controls="pills-location" aria-selected="false">{i18n.t('reports.detailed.location-detail',"Location detail")}</a>
                </li>
            </ul>


            <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-12 mt-5">
                    <ReportBasiicInfo
                        selectedWork={props.selectedWork}
                    >
                    </ReportBasiicInfo>
                </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade active show" id="pills-task" role="tabpanel"
                     aria-labelledby="pills-task-tab">

                    <TaskReport
                        selectedWork={props.selectedWork}
                    ></TaskReport>
                </div>
                <div className="tab-pane fade" id="pills-location" role="tabpanel" aria-labelledby="pills-location-tab">

                    <LocationReport
                        selectedWork={props.selectedWork}
                    ></LocationReport>
                </div>
            </div>


        </div>
    )
}

const mapContainerStyle = {
    height: "800px",
    width: "100%",
};
const options = {
    disableDefaultUI: false,
    zoomControl: true,
};

export function LocationReport(props) {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allLocationIds = useSelector((state) => state.locations.byId);
    const [locations, setLocations] = useState([]);
    const [markers, setMarkers] = useState([]);
    const selectedWork = props.selectedWork;
    const selectedSite = selectedWork.locationId;
    const libraries = ["visualization"];
    useEffect(
        async () => {

            const res = await getPolyLineMapData(companyState.id, selectedWork.workId);
            if (res && res.success) {
                const data = res.data;
                if (data && data.locations && data.locations.length > 0) {
                    let l = [];
                    data.locations.forEach((loc) => {
                        l.push({
                            "lat": loc.lat,
                            "lng": loc.lng
                        });

                    });
                    setLocations([...l]);


                }
            }
        },
        [props.selectedWork]
    );
    useEffect(
        () => {
            let st = getCenter();
            let m = [];


            if (st) {
                m.push({
                    position: {...st},
                    icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
                    title: "Start location"
                });
            }

            if (locations && locations.length > 1) {
                let en = locations[locations.length - 1];

                m.push({
                    position: {...en},
                    icon: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
                    title: "End location"
                });
            }
            setMarkers([...m]);
        },
        [locations]
    )
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey: config.GOOGLE_API_KEY,
        libraries,

    });
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = useCallback(({lat, lng}) => {
        mapRef.current.panTo({lat, lng});
        mapRef.current.setZoom(13);
    }, []);
    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";

    function getCenter() {
        if (locations && locations[0]) {
            return {
                lat: locations[0].lat,
                lng: locations[0].lng
            };
        } else if (selectedSite != -1) {
            const site = allLocationIds[selectedSite];
            if (site && site.lat) {
                return {
                    lat: site.lat,
                    lng: site.lang
                };
            } else if (locations && locations[0]) {
                return {
                    lat: locations[0].lat,
                    lng: locations[0].lng
                };
            }
        }
        return {
            lat: 25.2744,
            lng: 133.7751
        };
    }

    return (

        <>
            <div className="row grid-margin mt-5">
                <div className="col-12">

                    <GoogleMap
                        id="map2"
                        mapContainerStyle={mapContainerStyle}
                        zoom={locations.length > 0 != -1 ? 17 : 8}
                        center={getCenter()}
                        options={options}
                        onLoad={onMapLoad}


                    >

                        <Polyline
                            path={locations}


                            options={{
                                geodesic: true,
                                strokeColor: '#FF0000',
                                strokeOpacity: 0.7,
                                strokeWeight: 10,
                                icons: [{
                                    icon: "hello",
                                    offset: '0',
                                    repeat: '10px'
                                }],
                            }}
                        />
                        {markers && markers.map(marker => (
                            <Marker
                                {...marker}
                                onClick={(p) => {
                                    panTo(marker.position)
                                }
                                }
                            />
                        ))}

                    </GoogleMap>
                </div>
            </div>
        </>
    );

}

export function TaskReport(props) {
    const selectedWork = props.selectedWork;
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    const [overallAvg, setOverallAvg] = useState(0);
    useEffect(
        () => {
            const avg = getOverallRating(selectedWork && selectedWork.jobSpecTypeListMap &&
                selectedWork.jobSpecTypeListMap['INSPECTION'] ? selectedWork.jobSpecTypeListMap['INSPECTION'] : [])
            ;
            setOverallAvg(avg);
        },
        [props.selectedWork]
    );
    return (
        <div>
            {
                selectedWork && selectedWork.jobSpecTypeListMap &&

                (
                    <>
                        {
                            Object.keys(selectedWork.jobSpecTypeListMap).map(
                                (key) => {
                                    return (
                                        <div className="row">
                                            <div className="col-lg-12 mt-4">
                                                <h3 className="card-title">{key === 'INSPECTION' ? i18n.t('forms.type.inspections',"Inspections")
                                                    :i18n.t('forms.type.tasks',"Tasks")}</h3>

                                                <div className="row">
                                                    <div className="col-lg-12 mt-4">
                                                        <table className="table-custom">
                                                            {
                                                                selectedWork.jobSpecTypeListMap[key].map(
                                                                    (spec) => {
                                                                        return (<AnsweredSpec
                                                                                spec={spec}
                                                                            />

                                                                        )
                                                                    }
                                                                )
                                                            }
                                                        </table>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                        }


                        {!props.popup && (
                            <div className="row">
                                <div className="col-sm-12 col-md-8  mt-5">
                                    <div className="table-responsive ">

                                        <table className="table-custom">
                                            <tbody>
                                            <tr>
                                                <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.reviewed-by', "Reviewed By")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.signature', "Signature")}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="font-weight-bold p-3 pb-0  pr-2">    {i18n.t('report.footer.date', "Date")}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}


                    </>


                )

            }

        </div>
    )
}


export function AnsweredSpec(props) {
    const spec = props.spec;

    return (


        <tbody>
        <tr>
            <th><h4>{spec.title}</h4></th>

        </tr>
        <tr>
            <td>
                <table className="table-striped w-100">
                    <tbody>


                    {spec.specItems && spec.specItems.map(
                        (specItem, i) => {
                            return (
                                <tr>
                                    <td>


                                        <AnsweredSpecItem
                                            specItem={specItem}
                                            count={spec.specItems.length}
                                            first={i == 0}
                                            jobSpecType={spec.jobSpecType}
                                            totalAverage={spec.averageRating}
                                        />

                                    </td>
                                </tr>)

                        }
                    )}
                    </tbody>
                </table>
            </td>

        </tr>

        </tbody>
    );
}

function getClassname(avg) {
    let className = "display-3 p-0 font-weight-bold text-align-center ";
    className += avg >= 4.5 ? "text-five" :
        avg > 4 ? "text-four" :
            avg > 3.5 ? "text-three" :
                avg > 3 ? "text-two" :
                    "text-one";

    return className;

}

export function AnsweredSpecItem(props) {

    const specItem = props.specItem;
    const count = props.count;
    const jobSpecType = props.jobSpecType;
    const avg = props.totalAverage ? props.totalAverage : 0;
    const [orderedQuestions, setOrderedQuestions] = useState([]);
    const ANSWER_SPLITTER = ";;";

    useEffect(() => {
        setOrderedQuestions([...getOrderedQuestions(specItem.questionnaires)])
    }, [props.specItem])


    function addQuestionForType(orderedQues, questions, at) {
        if (questions) {
            for (let q of questions) {
                if (q.answerType === at && q.answer) {
                    if (q.answerType == 'BEFORE_IMAGES' || q.answerType == 'AFTER_IMAGES' || q.answerType == 'FILE') {
                        q['urls'] = q.answer.split(ANSWER_SPLITTER);
                    } else if (q.answerType == 'CHECKBOX') {
                        q.answer = q.answer ? "Yes" : "Not completed";
                    }
                    orderedQues.push(q);
                }
            }

        }
    }

    const defaultTitles = {
        'CHECKBOX': 'Completed',
        'RATING': 'Rating Given',
        'BEFORE_IMAGES': 'Before Images',
        'AFTER_IMAGES': 'After Images',
        'TEXT': 'Comments',
        'FILE': 'Files'
    }

    function getOrderedQuestions(questions) {
        var orderedQues = [];
        if (questions && questions.length > 0) {
            addQuestionForType(orderedQues, questions, 'CHECKBOX')
            addQuestionForType(orderedQues, questions, 'RATING')
            addQuestionForType(orderedQues, questions, 'BEFORE_IMAGES')
            addQuestionForType(orderedQues, questions, 'AFTER_IMAGES')
            addQuestionForType(orderedQues, questions, 'TEXT')
            addQuestionForType(orderedQues, questions, 'FILE')
        }
        return orderedQues;
    }

    function getComment(questions) {
        let comment = "";
        if (questions) {
            questions.forEach((q) => {
                if (q.answerType === 'TEXT' && q.answer) {
                    comment += q.answer;
                }
            })
        }

        return comment;
    }

    function getAvg() {
        if (jobSpecType !== 'INSPECTION') {
            return "";
        }
        return Math.ceil((avg / 5.0) * 100) + "%";
    }

    function downloadFile(url) {
        window.open(url, "_blank");
    }

    function openFile(url, title) {
        let wrapper = document.createElement('div');
        ReactDOM.render(
            <img
                className="full-img"
                src={url}
            ></img>


            , wrapper);
        let el = wrapper.firstChild;
        swal({
            title: title,
            html: true,
            type: "info",
            className: 'swal-wide',
            content: el,

            buttons: {
                cancel: "Close",
                success: 'Download'
            }

        }).then((val) => {
            switch (val) {
                case 'success':
                    downloadFile(url);
                    break;
            }
        });
    }

    function getUrlComponent(url, title) {
        const imagesExt = [".jpg", ".jpeg", ".gif", ".png", ".heic"];
        const filesExt = [".doc", ".docx", ".pdf", ".xlsx", ".xls", ".txt"];

        let type = 'other';
        let fileType = 'File';
        imagesExt.forEach((e) => {
            if (url.indexOf(e) != -1) {
                type = 'image';
                fileType = e;
            }
        })
        if (type === 'other') {
            filesExt.forEach((e) => {
                if (url.indexOf(e) != -1) {
                    type = 'file';
                    fileType = e;

                }
            })
        }
        return (
            <div className="flex">
                <div style={{
                    display: 'inline-block',
                    position: 'relative',
                    width: '300px',
                    maxHeight: '500px',
                    cursor: 'pointer'
                }}
                     onClick={() => {
                         if (type == 'image') {
                             openFile(url, title)
                         } else {
                             downloadFile(url);
                         }
                     }}
                >

                    <img
                        style={{
                            display: 'block',
                            width: '300px',
                            maxHeight: '400px',
                            minHeight: '300px',
                            objectFit: 'cover'


                        }}

                        src={type == 'image' ? url : fileImage}></img>
                </div>
                {type === 'file' && (<span className="display-5">{fileType}</span>)}

            </div>
        )

    }

    return (
        <div className="col mt-2 mb-2">
            <div className="row ">
                <div><h5>{specItem.title}</h5></div>

            </div>
            <div className="table">

                {
                    orderedQuestions.map((q) => {
                        return (<div className="row">
                            <div className="col-md-3 pt-2 pl-4">
                                {q.title ? q.title : defaultTitles[q.answerType]}
                            </div>

                            <div className="col-md-9 flex">
                                <div className="flex m-2 flex-wrap">

                                    {
                                        q.urls && q.urls.length > 0 ? (
                                                q.urls.map((url) => {
                                                    return (
                                                        <div className=" m-2">
                                                            {
                                                                getUrlComponent(url,
                                                                    q.title ? q.title : defaultTitles[q.answerType]
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                })
                                            ) :


                                            (<>
                                                {q.answer}
                                            </>)


                                    }
                                </div>
                            </div>
                        </div>)
                    })
                }
            </div>

        </div>


    );
}


export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const selectedWork = props.selectedWork;
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);

    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }

    useEffect(() => {
        const client = allClientData[selectedWork.clientId];
        const user = allClientData[selectedWork.tenantUserId];
        const location = allLocationIds[selectedWork.locationId];
        const map = {};

        map['Client'] = getObj(client.user.firstName + " " + client.user.lastName, 3);
        map['Site Address'] = getObj(location.address, 3);
        map['Inspected By'] = getObj(user.user.firstName + " " + user.user.lastName, 3);
        setDmap(map);
    }, [props.selectedWork])
    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>
                        <tr>
                            <td className="font-weight-bold p-0 pb-0  pr-2">Started Date</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.startTime).format('MMMM D, YYYY')}</td>

                            <td className="font-weight-bold p-0  pl-2">Started Time</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.startTime).format('HH:mm:SS')}</td>
                        </tr>

                        <tr>
                            <td className="font-weight-bold p-0 pb-0  pr-2">End Date</td>
                            <td className="pl-2 p-0 pb-0 pt-1 ">{moment(selectedWork.endTime).format('MMMM D, YYYY')}</td>

                            <td className="font-weight-bold p-0 pb-0  pl-2">End Time</td>
                            <td className="pl-2 p-0 pb-0 pt-1">{moment(selectedWork.endTime).format('HH:mm:SS')}</td>
                        </tr>
                        {
                            Object.keys(dMap).map((key) => {
                                return (<tr>
                                    <td className="font-weight-bold p-0 pb-0  pr-2">{key}</td>
                                    <td className="pl-2 p-0 pb-0 pt-1" colSpan={dMap[key].span}>{dMap[key].value} </td>
                                </tr>)
                            })
                        }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


