import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
  groups:[]
};

const conversationsSlice = createSlice({
  name: "conversations",
  initialState: initState,
  reducers: {
    setConversations(state, { action, payload }) {
      for (var conversation of payload) {
        const { locations, ...rest } = conversation;
        if (!(conversation.id in state.byId)) {
          state.byId[conversation.id] = rest;
          state.allIds.push(conversation.id);
        }
      }
    },
    // setLocationUpdate(state, { action, payload }) {
    //   state.byId[payload.id] = payload;
    // },
    // removeLocation(state, { action, payload }) {
    //   state.allIds = state.allIds.filter((id) => id !== payload);
    //   delete state.byId[payload];
    // },
  },
});

export const conversationActions = conversationsSlice.actions;
export const conversationReducer = conversationsSlice.reducer;

export function setConversations(data) {
  return (dispatch, getState) => {
    try {
      dispatch(conversationActions.setConversations(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting locations"));
    }
  };
}

// export function createLocation(data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.createLocation(data);
//       dispatch(locationActions.setLocationUpdate(response.data));
//       dispatch(showToastSuccess("Location created", "Created succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function updateLocation(locationId, data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.updateLocation(locationId, data);
//       dispatch(locationActions.setLocationUpdate(response.data));
//       dispatch(showToastSuccess("Location updated", "Updated succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function deleteLocation(locationId) {
//   return async (dispatch, getState) => {
//     try {
//       await api.deleteLocation(locationId);
//       dispatch(locationActions.removeLocation(locationId));
//       dispatch(showToastSuccess("Location deleted", "Deleted succesfully!"));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error deleting location"));
//     }
//   };
// }
