import { PageLayout } from "../../../components/pageLayout";
import { ReportFilter } from "./shared/filter";
import React, { useState, useEffect, useRef, createRef, useCallback } from "react";
import { getHeatMapData } from "../../../api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import ReactToPdf from "react-to-pdf";
import moment from 'moment';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { formatDate } from "../../../utils/dateUtils";
import { store } from "../../../state/store";
import { setUsers } from "../../../state/users";
import {
    GoogleMap,
    useLoadScript,
    Marker,
    LatLng,
    HeatmapLayer,

} from "@react-google-maps/api";

import * as config from "../../../config";
import i18n from "i18next";
/*global google*/

const accuracy = {
    "Low": 3,
    "Medium": 5,
    "High": 7
}

export function HeatMap() {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );
    const allClientData = useSelector((state) => state.users.byId);
    const allClientIds = useSelector((state) => state.users.allIds);

    const allLocationIds = useSelector((state) => state.locations.byId);


    const [selectedSpec, setSelectedSpec] = useState("");
    const [selectedSite, setSelectedSite] = useState(-1);
    const [locations, setLocations] = useState();
    const [selectedUser, setSelectedUser] = useState(-1);
    const [locationAccuracy, setLocationAccuracy] = useState('Low');

    const [dates, setDates] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });

    useEffect(() => {

    }, [])
    async function onSiteChange(e) {
        setSelectedSite(e.target.value);
    }
    async function onUserChange(e) {
        setSelectedUser(e.target.value);
    }
    async function onDateRangeChange(st, en) {
        setDates({ start: st, end: en });
    }
    async function _loadReport() {
        let error = "";
        let site = null;
        let user = null;
        const locationAccuracy = document.getElementById('location-accuracy') ? document.getElementById('location-accuracy').value : 'Low';
        setLocationAccuracy(locationAccuracy);

        if (selectedSite != -1) {
            site = selectedSite;
        }

        if (selectedUser != -1) {
            user = selectedUser;
        }
        if (error === '') {
            const res = await getHeatMapData(companyState.id, user, site, formatDate(dates.start), formatDate(dates.end), locationAccuracy.toUpperCase());
            if (res && res.data && res.data.locations) {
                setLocations(res.data.locations);
            } else {
                swal({
                    title: "Error",
                    text: res && res.message ? res.message : "Something went wrong",
                    icon: 'error'
                });
            }

        } else {
            swal({
                title: "Error",
                text: error,
                icon: 'error'
            });
        }
    }




    return (
        <PageLayout>
            <div className="content-wrapper job-location">
                <div className="col-sm-12 grid-margin stretch-card">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">    {i18n.t('reports.heat-map.title',"Heat Map")}
                            </h4>
                            <p className="card-description">    {i18n.t('reports.heat-map.description',"View heat map according to employees GPS locations")}
                            </p>

                            <div className="row">
                                <div className="mt-2 mb-2 filter col-sm-12">
                                    <ReportFilter
                                        key="heatMapFilter"
                                        onSiteChange={onSiteChange}
                                        onDateRangeChange={onDateRangeChange}
                                        allLocationIds={allLocationIds}
                                        locationFilter={true}
                                        userFilter={true}
                                        onUserChange={onUserChange}
                                        start={dates.start}
                                        end={dates.end}
                                        allUserIds={Object.values(allClientData)}
                                        allUsers={true}
                                        allSites={true}
                                    >
                                    </ReportFilter>

                                </div>

                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3 col-lg-2" id="date-range">

                                    <label htmlFor="location-accuracy">    {i18n.t('forms.location-accuracy',"Location Accuracy")}
                                    </label>
                                    <select
                                        className="form-control form-control-sm"
                                        id="location-accuracy"
                                        required

                                    >
                                        <option value="Low">    {i18n.t('forms.location-accuracy.low',"Low")}
                                        </option>
                                        <option value="Medium">    {i18n.t('forms.location-accuracy.medium',"Medium")}
                                        </option>
                                        <option value="High" >    {i18n.t('forms.location-accuracy.high',"High")}
                                        </option>

                                    </select>
                                </div>
                            </div>


                            <div className="row">

                                <div className="col">
                                    <button type="button"
                                        onClick={(e) => _loadReport()}
                                        className="btn btn-primary btn-lg">    {i18n.t('btn.generate',"Generate")}
                                    </button>

                                </div>
                            </div>





                        </div>
                    </div>

                </div>
                {
                    locations && (
                        <div className="row">
                            <div className="col-sm-12">
                                <HeatMapReport
                                    key="heatMapReport"
                                    locations={locations}
                                    selectedSite={selectedSite}
                                    selectedUser={selectedUser}
                                    startDate={dates.start}
                                    endDate={dates.end}
                                    locationAccuracy={locationAccuracy}
                                >
                                </HeatMapReport>

                            </div>
                        </div>
                    )
                }

            </div>
        </PageLayout>
    );

}


export function HeatMapReport(props) {
    const ref = createRef();
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );

    function getFileName() {
        return "heatMap.pdf";
    }
    const options = {
        orientation: 'p',
        format: 'a4',
        unit: 'in',
    };

    return (
        <div>
            <div className="col-sm-12 grid-margin stretch-card">
                <div className="card">
                    <div className="card-body">
                        <div className="row flex justify-content-between">
                            <h4 className="card-title">    {i18n.t('reports.heat-map.report.title',"Report")}
                            </h4>

                        </div>

                        <Report
                            selectedSite={props.selectedSite}
                            selectedUser={props.selectedUser}
                            locations={props.locations}
                            startDate={props.startDate}
                            endDate={props.endDate}
                            locationAccuracy={props.locationAccuracy}
                        />
                    </div>
                </div>
            </div>
        </div>
    );


}

export function Report(props) {
    const companyState = useSelector(
        (companyState) => companyState.company.profile
    );


    return (
        <div if="pdf">

            {/* <div className="row flex justify-content-end">
                <div className="col-lg-12 mt-5 flex justify-content-end">
                    <img className="header-logo" src={companyState.logoURL}></img>
                </div>
            </div>


            <div className="row">
                <div className="col-sm-12 mt-5">
                    <ReportBasiicInfo
                        selectedSite={props.selectedSite}
                        selectedUser={props.selectedUser}
                        startDate={props.startDate}
                        endDate={props.endDate}
                    >
                    </ReportBasiicInfo>
                </div>
            </div> */}

            {
                props.locations &&

                (
                    <>
                        <div className="row grid-margin mt-5">
                            <div className="col-12">
                                <MapComponent
                                    key="mapC"
                                    locations={props.locations}
                                    locationAccuracy={props.locationAccuracy}
                                    selectedSite={props.selectedSite}
                                >

                                </MapComponent>
                            </div>

                        </div>

                    </>
                )

            }

        </div>
    )
}
const mapContainerStyle = {
    height: "800px",
    width: "100%",
};
const options = {
    disableDefaultUI: false,
    zoomControl: true,
};
const heatMapLocationAccuracy = 7;
export const MapComponent = (props) => {
    const locations = props.locations;
    const selectedSite = props.selectedSite;
    const libraries = ["visualization"];
    const allLocationIds = useSelector((state) => state.locations.byId);
    const [center, setCenter] = useState({
        lat: 25.2744,
        lng: 133.7751
    });
    const [heatMapData, setHeatMapData] = useState([]);

    function getCenter() {
        if (selectedSite != -1) {
            const site = allLocationIds[selectedSite];
            if (site && site.lat) {
                return {
                    lat: site.lat,
                    lng: site.lang
                };
            } else if (locations && locations[0]) {
                return {
                    lat: locations[0].lat,
                    lng: locations[0].lng
                };
            }
        } else if (locations && locations[0]) {
            return {
                lat: locations[0].lat,
                lng: locations[0].lng
            };
        }
        return {
            lat: 25.2744,
            lng: 133.7751
        };
    }

    function getHData() {
        const data = [];
        locations.forEach((loc) => {
            data.push({
                location: new google.maps.LatLng(parseFloat(loc.lat), parseFloat(loc.lng)),
                weight: loc.count
            })

        });
        //console.log((data);)
        return Object.values(data);
    }
    function getKey(loc) {
        return loc && loc.lat ?
            loc.lat.toFixed(accuracy[props.locationAccuracy]) + "_" + loc.lng.toFixed(accuracy[props.locationAccuracy]) : "";
    }
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: config.GOOGLE_API_KEY,
        libraries,

    });
    const gradient = [
        "rgba(0, 255, 255, 0)",
        "rgba(0, 170, 255, 1)",
        "rgba(0, 160, 255, 1)",
        "rgba(0, 127, 255, 1)",
        "rgba(0, 63, 255, 1)",
        "rgba(0, 0, 255, 1)",
        "rgba(0, 0, 223, 1)",
        "rgba(0, 0, 191, 1)",
        "rgba(0, 0, 159, 1)",
        "rgba(0, 0, 127, 1)",
        "rgba(63, 0, 91, 1)",
        "rgba(127, 0, 63, 1)",
        "rgba(191, 0, 31, 1)",
        "rgba(255, 0, 0, 1)",
    ];
    const mapRef = useRef();
    const onMapLoad = useCallback((map) => {
        mapRef.current = map;
    }, []);

    const panTo = useCallback(({ lat, lng }) => {
        mapRef.current.panTo({ lat, lng });
        mapRef.current.setZoom(13);
    }, []);
    if (loadError) return "Error";
    if (!isLoaded) return "Loading...";
    return (
        <div>


            <GoogleMap
                id="map2"
                mapContainerStyle={mapContainerStyle}
                zoom={selectedSite != -1 ? 12 : 4}
                center={getCenter()}
                options={options}
                onLoad={onMapLoad}

            >

                <HeatmapLayer
                    data={getHData()}
                    options={{
                        radius: (70 / (accuracy[props.locationAccuracy] * 0.5)),
                        gradient: gradient,
                        maxIntensity: 8
                    }

                    }
                />

            </GoogleMap>

        </div>
    );
};
export function ReportBasiicInfo(props) {
    const allClientData = useSelector((state) => state.users.byId);
    const [dMap, setDmap] = useState({});
    const allLocationIds = useSelector((state) => state.locations.byId);

    function getObj(val, span) {
        return {
            value: val,
            span: span ? span : 1
        }
    }
    useEffect(() => {
        const location = allLocationIds[props.selectedSite];
        const user = allClientData[props.selectedUser];
        const map = {};
        if (user && user.user) {
            const name = user.user.firstName + " " + user.user.lastName;
            map[i18n.t('forms.employee',"Employee")] = getObj(name, 3);
        }
        if (location) {
            map[i18n.t('report.site-address',"Site Address")] = getObj(location.address, 3);
        }
        setDmap(map);
    }, [])
    return (
        <div className="row">
            <div className="col-sm-12 col-md-8 ">
                <div className="table-responsive border">

                    <table className="table-custom">
                        <tbody>
                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">Started Date</td>
                                <td className="pl-2 p-0 pb-0 pt-1">{moment(props.startDate).format('MMMM D, YYYY')}</td>
                            </tr>

                            <tr>
                                <td className="font-weight-bold p-0 pb-0  pr-2">End Date</td>
                                <td className="pl-2 p-0 pb-0 pt-1 ">{moment(props.endDate).format('MMMM D, YYYY')}</td>

                            </tr>
                            {
                                Object.keys(dMap).map((key) => {
                                    return (<tr>
                                        <td className="font-weight-bold p-0 pb-0  pr-2">{key}</td>
                                        <td className="pl-2 p-0 pb-0 pt-1" colSpan={dMap[key].span}>{dMap[key].value} </td>
                                    </tr>)
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    );
}


