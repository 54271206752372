import { createSlice } from "@reduxjs/toolkit";
import * as api from "../api";

const initState = {
  byId: {},
  allIds: [],
};

const locationsSlice = createSlice({
  name: "locations",
  initialState: initState,
  reducers: {
    setLocations(state, { action, payload }) {
      for (var location of payload) {
        const { locations, ...rest } = location;
        if (!(location.id in state.byId)) {
          state.byId[location.id] = rest;
          state.allIds.push(location.id);
        }
      }
    },
    clearLocations(state,{action}){
      state.byId={};
      state.allIds=[];
    }
    // setLocationUpdate(state, { action, payload }) {
    //   state.byId[payload.id] = payload;
    // },
    // removeLocation(state, { action, payload }) {
    //   state.allIds = state.allIds.filter((id) => id !== payload);
    //   delete state.byId[payload];
    // },
  },
});

export const locationActions = locationsSlice.actions;
export const locationReducer = locationsSlice.reducer;

export function setLocations(data) {
  return (dispatch, getState) => {
    try {
      dispatch(locationActions.setLocations(data));
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting locations"));
    }
  };
}

export function clearLocations() {
  return (dispatch, getState) => {
    try {
      dispatch(locationActions.clearLocations());
    } catch {
      //console.log(("Error");)
      // dispatch(showToastError("Error", "Error getting users"));
    }
  };
}

// export function createLocation(data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.createLocation(data);
//       dispatch(locationActions.setLocationUpdate(response.data));
//       dispatch(showToastSuccess("Location created", "Created succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function updateLocation(locationId, data) {
//   return async (dispatch, getState) => {
//     try {
//       const response = await api.updateLocation(locationId, data);
//       dispatch(locationActions.setLocationUpdate(response.data));
//       dispatch(showToastSuccess("Location updated", "Updated succesfully!"));
//       return response.data;
//     } catch (e) {
//       return e.response;
//     }
//   };
// }

// export function deleteLocation(locationId) {
//   return async (dispatch, getState) => {
//     try {
//       await api.deleteLocation(locationId);
//       dispatch(locationActions.removeLocation(locationId));
//       dispatch(showToastSuccess("Location deleted", "Deleted succesfully!"));
//     } catch (e) {
//       dispatch(showToastError("Error", "Error deleting location"));
//     }
//   };
// }
